import { CPDate } from '@cp-shared-8/common-utilities';

export type OldRegistrationData = {
    contractNumber: string;
    firstName: string;
    surname: string;
    dateOfBirth: CPDate;
};

export type OldRegistrationResult = {
    customerNumber: string;
};

export enum OldRegistrationError {
    CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
    CONTRACT_NOT_YET_IN_SCOPE = 'CONTRACT_NOT_YET_IN_SCOPE',
    CONTRACT_TERMINATED = 'CONTRACT_TERMINATED',
}
