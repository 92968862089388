export enum Gender {
    M,
    F,
}
export type BaseCustomerData = {
    name?: string;
    surname?: string;
    customerNumber?: string;
    gender?: Gender;
    /** if user is eligible to npai notification */
    isEligibleNpaiNotification?: boolean;
};

export type RetailCustomerData = BaseCustomerData;

export type SmeCustomerData = BaseCustomerData & {
    companyName?: string;
    sirenNumber?: string;
};

type RetailCustomerIdentification = {
    customerType: 'Retail';
    data: RetailCustomerData;
};

type SmeCustomerIdentification = {
    customerType: 'SME' | 'PM' | 'BIC' | 'BA' | 'BNC';
    data: SmeCustomerData;
};

export type Identification = RetailCustomerIdentification | SmeCustomerIdentification;
