import React, { useCallback } from 'react';
import { Combobox, ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { FormikProps, useField, useFormikContext } from 'formik';
import { ComboboxOption } from 'types/bronson-react';
import { RequestInitialValues } from 'components/request/form-view/initialValues';

type Props = {
    label: string;
    name: string;
};

export const CauseOfSinistreSelection: React.FC<Props> = ({ label, name }) => {
    const [, meta, helpers] = useField(name);
    const { initialValues }: FormikProps<RequestInitialValues> = useFormikContext();
    const list: string[] = [
        'Le sinistre a été causé intentionnellement',
        'Le sinistre a été causé par le conducteur sans permis de conduire adapté',
        'Le sinistre résulte de l’état d’ivresse ou de la conduite sous l’emprise de stupéfiants',
        'Les dommages survenus au cours d’épreuve, courses, compétitions (ou de leur essai)',
        'Les conséquences de vol commis par des préposés ou des membres de la famille de l’assuré',
        'Le sinistres a été causé ou aggravé par les matières dangereuses et destructives transportées dans le véhicule',
        'Le véhicule est en sous location',
        'Le véhicule a été utilisé en tant qu’ambulance',
        'Le véhicule a été utilisé pour une activité de transports urbains et routiers de personnes',
        'Le véhicule a été utilisé pour une activité de messagerie',
        'Le véhicule a été utilisé pour une  activité de transport de marchandise',
    ];

    const renderOptions = (): JSX.Element[] | null => {
        return list.map((item: string) => (
            <Combobox.Item testId={`contract-selection-item-${item}`} optionValue={item} optionKey={item} key={item}>
                {item}
            </Combobox.Item>
        ));
    };

    const setValueChange = useCallback((selectedOptions: ComboboxOption[]): void => {
        return helpers.setValue(
            selectedOptions.map((item) => item.value) || initialValues.vehicleClaim.nonReparableFields.causeOfSinistre,
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormField
            type="select"
            testId={`test-${name}`}
            id={'category-selection'}
            errorMessage={
                meta.touched && meta.error && <ErrorMessage testId="textarea-error-message">{meta.error}</ErrorMessage>
            }
            labelText={label}
        >
            <Combobox testId="custom-contract-select" onChange={setValueChange} multiple={true}>
                {renderOptions()}
            </Combobox>
        </FormField>
    );
};
