import * as Yup from 'yup';
import { IbanValidatorErrors } from '../types';
import { IbanValidatorError } from '../messages';
import { isValidLength, stripWhitespaces, isValidCountryCode } from './utils/iban/ibanValidationsUtils';
import { IbanStateHandler, SetIsValidating } from './utils/iban/ibanTypes';
import { BankData, SignedData } from '@cp-shared-8/apis';
import { AxiosError } from 'axios';

export type IbanFormType = {
    iban: string | undefined;
    ibanHolder: string;
};

const getIbanError = (
    errorCode: string,
    errors: IbanValidatorErrors = IbanValidatorError,
    iban?: string,
): Yup.ValidationError => {
    switch (errorCode) {
        case 'INCORRECT_IBAN':
            return new Yup.ValidationError(errors.iban.invalidIban, iban, 'iban');
        default:
            return new Yup.ValidationError(errors.iban.ibanValidatorUnavailable, iban, 'iban');
    }
};

export const getIbanValidation = (errors: IbanValidatorErrors = IbanValidatorError): Yup.StringSchema =>
    Yup.string()
        .required(errors.iban.required)
        .test('validCountryCode', errors.iban.validCountryCode, isValidCountryCode)
        .matches(RegExp('^[A-Z]{2}[a-zA-Z0-9_ ]*$'), errors.iban.invalidIban)
        .test('ibanLength', errors.iban.ibanLength, isValidLength);

export const ibanValidationCoreSchema = (
    errors: IbanValidatorErrors = IbanValidatorError,
): Yup.SchemaOf<Omit<IbanFormType, 'iban'>> => {
    return Yup.object().shape({
        ibanHolder: Yup.string().trim().required(errors.ibanHolder.required).max(50, errors.ibanHolder.maxLength),
    });
};

export const ibanValidationFrontendSchema = (
    setIsValidating: SetIsValidating,
    ibanStateHandler: IbanStateHandler,
    currentIban: string,
    validateApiFunc: Function,
    getErrorCode: Function,
    errors: IbanValidatorErrors = IbanValidatorError,
): Yup.SchemaOf<IbanFormType> => {
    const { savedIban, setSavedIban, setSignedBankData } = ibanStateHandler;

    const ibanValidation = getIbanValidation(errors).test(
        'sameIban',
        errors.iban.sameIban,
        (str) => stripWhitespaces(str) !== currentIban,
    );

    const isIbanFormated = (iban?: string): boolean => {
        return !!(iban && iban.indexOf(' ') > 0);
    };

    return ibanValidationCoreSchema(errors).shape({
        iban: ibanValidation.test('asyncIban', errors.iban.invalidIban, async (iban?: string | null) => {
            if (!ibanValidation.isValidSync(iban) || !isIbanFormated(iban)) {
                setSignedBankData(undefined);
                setSavedIban({});
                return true;
            }
            if (savedIban.iban === iban) {
                if (!savedIban.error) {
                    return true;
                }
                return getIbanError(savedIban.error, errors, savedIban.iban);
            }
            await setIsValidating(true);
            return await validateApiFunc(iban)
                .then(({ data }: { data: SignedData<BankData> }) => {
                    const { isValid } = data.data;
                    setSignedBankData(isValid ? data : undefined);
                    setSavedIban({ iban, error: isValid ? undefined : 'INCORRECT_IBAN' });
                    return isValid;
                })
                .catch((error: AxiosError) => {
                    const errorCode = getErrorCode(error);
                    setSavedIban({ iban, error: errorCode });
                    return getIbanError(errorCode, errors, iban);
                })
                .finally(async () => {
                    await setIsValidating(false);
                });
        }),
    });
};

export const ibanValidationBackendSchema = (
    errors: IbanValidatorErrors = IbanValidatorError,
): Yup.SchemaOf<IbanFormType> => {
    return ibanValidationCoreSchema(errors)
        .shape({
            iban: getIbanValidation(errors).notOneOf([Yup.ref('currentIban')], errors.iban.sameIban),
        })
        .defined();
};
