import { preventSubmit, ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from 'components/navigation/paths';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EarlySettlementRequestDate, totalEarlySettlementValidationSchema } from '@cp-fr/common';
import { getInitialValues } from './initialValues';

export type FormData = {
    requestDate: EarlySettlementRequestDate;
    reason: string;
};

type TotalSettlementFormProps = {
    handleSubmit: (data: FormData) => void;
};

const availableReasons: ValidatedSelectItem[] = [
    {
        label: 'Revente du véhicule',
        value: 'VEHICLE_RESALE',
    },
    {
        label: 'Rachat de crédit',
        value: 'CREDIT_REPURCHASE',
    },
    {
        label: 'Epargne personnelle',
        value: 'PERSONAL_SAVINGS',
    },
    {
        label: 'Prime',
        value: 'BONUS',
    },
    {
        label: 'Héritage',
        value: 'HERITAGE',
    },
];

const availableMonths: ValidatedSelectItem[] = [
    { label: 'Janvier', value: 'JANUARY' },
    { label: 'Février', value: 'FEBRUARY' },
    { label: 'Mars', value: 'MARCH' },
    { label: 'Avril', value: 'APRIL' },
    { label: 'Mai', value: 'MAY' },
    { label: 'Juin', value: 'JUNE' },
    { label: 'Juillet', value: 'JULY' },
    { label: 'Août', value: 'AUGUST' },
    { label: 'Septembre', value: 'SEPTEMBER' },
    { label: 'Octobre', value: 'OCTOBER' },
    { label: 'Novembre', value: 'NOVEMBER' },
    { label: 'Décembre', value: 'DECEMBER' },
];

const translationPrefix = 'total-early-settlement.form';
const translationValidationPrefix = 'total-early-settlement.form.errors';
export const TotalEarlySettlementForm: React.FC<TotalSettlementFormProps> = ({ handleSubmit }) => {
    const { t } = useTranslation('early-settlement');
    const history = useHistory();

    const errorMessages = {
        requestDate: {
            required: t(`${translationValidationPrefix}.empty-date`),
        },
        reason: {
            required: t(`${translationValidationPrefix}.empty-reason`),
        },
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            onSubmit={(data: FormData): void => {
                handleSubmit(data);
            }}
            validationSchema={totalEarlySettlementValidationSchema(errorMessages)}
        >
            {(formik): JSX.Element => (
                <Form onSubmit={(e) => preventSubmit(e)} data-testid="total-settlement-form">
                    <Fieldset>
                        <Fieldset.Row>
                            <ValidatedSelect
                                name={'requestDate'}
                                label={t(`${translationPrefix}.request-date`)}
                                placeholder={t(`${translationPrefix}.request-date-placeholder`)}
                                selectItems={availableMonths}
                            />
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <ValidatedSelect
                                isMandatory={false}
                                name={'reason'}
                                label={t(`${translationPrefix}.reason`)}
                                placeholder={t(`${translationPrefix}.reason-placeholder`)}
                                selectItems={availableReasons.map(({ value, label }) => ({
                                    value,
                                    label,
                                }))}
                            />
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <ButtonContainer center>
                                <Button
                                    secondary
                                    onClick={(): void => history.push(dashboardPagePath())}
                                    testId="cancelButton"
                                >
                                    {t('translation:editable-section-nav.cancel')}
                                </Button>
                                <Button onClick={formik.handleSubmit} type="submit" testId="send">
                                    {t('translation:editable-section-nav.send')}
                                </Button>
                            </ButtonContainer>
                        </Fieldset.Row>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
