import * as Yup from 'yup';
import { RequestOtherValidatorErrors } from '../../types';
import { RequestOtherError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';

export type RequestOtherFormType = {
    email: string;
    phonenumber: string;
    subjectOfClaim: string;
    message: string;
};

export const requestOtherCoreSchema = (
    errors: RequestOtherValidatorErrors = RequestOtherError,
): Yup.SchemaOf<RequestOtherFormType> =>
    Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: Yup.string()
            .trim()
            .min(10, errors.phoneNumber.mustBePhoneNumber)
            .required(errors.phoneNumber.required),
        subjectOfClaim: Yup.string()
            .trim()
            .max(40, errors.subjectOfClaim.maxLength)
            .required(errors.subjectOfClaim.required),
        message: Yup.string().trim().required(errors.message.required).max(1000, errors.message.maxLength),
    });

export const requestOtherValidationSchema = (
    field: string,
    errors: RequestOtherValidatorErrors = RequestOtherError,
): Yup.SchemaOf<RequestOtherFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'OTHER';
        },
        then: requestOtherCoreSchema(errors),
    });
};
