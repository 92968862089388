import React from 'react';
import { LandingPage } from '@cp-fr/common';
import { HeroImage } from '@cp-shared-8/frontend-ui';

export type LandingPageUiProps = {
    landingPage?: LandingPage;
    clickHandler?: Function;
    isAuthenticated: boolean;
};

export const LandingPageUi: React.FC<LandingPageUiProps> = ({ landingPage, clickHandler, isAuthenticated }) => {
    if (!landingPage || !clickHandler) {
        return null;
    }

    const { title, subTitle, text, buttonText, buttonTextAuthenticated, imageUrl, isInverted = false } = landingPage;

    return (
        <HeroImage
            title={title}
            imageUrl={imageUrl}
            subTitle={subTitle}
            text={text}
            buttonText={isAuthenticated ? buttonTextAuthenticated : buttonText}
            clickHandler={clickHandler}
            inverted={isInverted}
        />
    );
};
