import { OriginalRegDocument, ProductTypeEN, YesNoSelections } from '@cp-fr/common';
import { ValidatedCheckbox } from '@cp-shared-8/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    DateInput,
    EmailInput,
    FileUpload,
    NatureOfDamageSelection,
    OriginalRegDocumentSelection,
    PhonenumberInput,
    PostcodeInput,
    TextArea,
    TextInput,
} from '../../simple-form-components';
import { ReparableSelection } from '../../simple-form-components/reparable/ReparableSelection';
import { BalloonUpload } from './BaloonUpload';
import { CreditUpload } from './CreditUpload';
import { CircumstancesOfDisasterSelection } from '../../simple-form-components/circumstances-of-disaster-selection/CircumstancesOfDisasterSelection';
import { CauseOfSinistreSelection } from '../../simple-form-components/cause-of-sinistre-selection/CauseOfSinistreSelection';

type VehicleClaimFormProps = {
    productTypeEn?: ProductTypeEN;
};

export const VehicleClaimForm: React.FC<VehicleClaimFormProps> = ({ productTypeEn }) => {
    const { t } = useTranslation('request');
    const [field] = useField('vehicleClaim.nonReparableFields.originalRegDocument');
    const [fieldReparable] = useField('vehicleClaim.reparable');
    const selectedOriginalRegDocument: OriginalRegDocument = field.value;
    const translationPrefix = 'category-selection.vehicle-claim';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const description =
        productTypeEn === ProductTypeEN.CREDIT
            ? translate('description.credit')
            : productTypeEn === ProductTypeEN.BALLON_LEASING ||
              productTypeEn === ProductTypeEN.OPERATIVE_LEASING ||
              productTypeEn === ProductTypeEN.FINANCIAL_LEASE
            ? translate('description.balloon')
            : '';

    const checkboxLabel = (
        <p
            dangerouslySetInnerHTML={{
                __html: translate('agree-to-send'),
            }}
        />
    );

    const isLeasing = (): boolean => {
        return (
            productTypeEn === ProductTypeEN.BALLON_LEASING ||
            productTypeEn === ProductTypeEN.OPERATIVE_LEASING ||
            productTypeEn === ProductTypeEN.FINANCIAL_LEASE
        );
    };

    const isCredit = (): boolean => {
        return productTypeEn === ProductTypeEN.CREDIT;
    };

    return (
        <>
            <Layout.Item>
                <ReparableSelection label={translate('reparable.label')} name={'vehicleClaim.reparable'} isMandatory />
            </Layout.Item>
            {fieldReparable.value === YesNoSelections.YES && (
                <Layout.Item>
                    <p>{translate('reparable.description')}</p>
                </Layout.Item>
            )}
            {fieldReparable.value === YesNoSelections.NO && (
                <>
                    <Layout.Item>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <EmailInput
                            label={translate('email')}
                            name={'vehicleClaim.nonReparableFields.email'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <PhonenumberInput
                            label={translate('phonenumber')}
                            name={'vehicleClaim.nonReparableFields.phonenumber'}
                            isMandatory={false}
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <NatureOfDamageSelection
                            label={translate('nature-of-damage.label')}
                            name={'vehicleClaim.nonReparableFields.natureOfDamage'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <DateInput
                            label={translate('date-of-damage')}
                            name={'vehicleClaim.nonReparableFields.dateOfDamage'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <CircumstancesOfDisasterSelection
                            label={translate('circumstances-of-the-disaster')}
                            name={'vehicleClaim.nonReparableFields.circumstancesOfTheDisaster'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <CauseOfSinistreSelection
                            label={translate('cause-of-sinistre')}
                            name={'vehicleClaim.nonReparableFields.causeOfSinistre'}
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <TextInput
                            label={translate('name-of-insurer')}
                            name={'vehicleClaim.nonReparableFields.nameOfInsurer'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <TextArea
                            label={translate('address-of-insurer')}
                            name={'vehicleClaim.nonReparableFields.addressOfInsurer'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <PostcodeInput
                            label={translate('postcode-of-insurer')}
                            name={'vehicleClaim.nonReparableFields.postcodeOfInsurer'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <TextInput
                            label={translate('city-of-insurer')}
                            name={'vehicleClaim.nonReparableFields.cityOfInsurer'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <TextInput
                            label={translate('insurance-customer-number')}
                            name={'vehicleClaim.nonReparableFields.insuranceCustomerNumber'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <TextInput
                            label={translate('damage-number')}
                            name={'vehicleClaim.nonReparableFields.damageNumber'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <PhonenumberInput
                            label={translate('phonenumber-of-insurer')}
                            name={'vehicleClaim.nonReparableFields.phonenumberOfInsurer'}
                            isMandatory
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <EmailInput
                            label={translate('email-of-insurer')}
                            name={'vehicleClaim.nonReparableFields.emailOfInsurer'}
                            isMandatory
                        />
                    </Layout.Item>
                    {isLeasing() && (
                        <Layout.Item>
                            <OriginalRegDocumentSelection
                                label={translate('original-reg-document')}
                                name={'vehicleClaim.nonReparableFields.originalRegDocument'}
                                hasTooltip={isLeasing()}
                                tooltipText={<div dangerouslySetInnerHTML={{ __html: translate('tooltip') }} />}
                            />
                        </Layout.Item>
                    )}
                    {isLeasing() && selectedOriginalRegDocument === YesNoSelections.NO && (
                        <Layout.Item>
                            <FileUpload
                                label={translate('original-reg-document-info')}
                                name={'vehicleClaim.nonReparableFields.registrationFile'}
                                isMandatory={false}
                            />
                        </Layout.Item>
                    )}
                    {isLeasing() && selectedOriginalRegDocument === YesNoSelections.YES && (
                        <Layout.Item>
                            <ValidatedCheckbox
                                name={'vehicleClaim.nonReparableFields.agreeToSend'}
                                label={checkboxLabel}
                                isMandatory
                            />
                        </Layout.Item>
                    )}
                    <Layout.Item>{translate('files.label')}</Layout.Item>
                    {isCredit() && <CreditUpload />}
                    {isLeasing() && <BalloonUpload />}
                    {selectedOriginalRegDocument === YesNoSelections.NO && (
                        <Layout.Item>
                            <FileUpload
                                name={'vehicleClaim.nonReparableFields.files.flightReport'}
                                label={translate('files.flight-report')}
                                isMandatory
                            />
                        </Layout.Item>
                    )}
                    <Layout.Item>
                        <p>
                            <b>{translate('correct-information-provided-confirmation')}</b>
                        </p>
                    </Layout.Item>
                </>
            )}
        </>
    );
};
