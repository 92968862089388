import { ProductTypeEN, RequestCategory, Contract, hasUnpaidAmmount, isVDLContract } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { Combobox, ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { FormikProps, useField, useFormikContext } from 'formik';
import { head, kebabCase, uniqBy } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestInitialValues } from '../initialValues';
import { ComboboxOption } from '../../../../types/bronson-react';

type CategorySelectionProps = {
    contractsSelection: Contract[];
    currentCategorySelection: string;
    resetForm: Function;
};

export const CategorySelection: React.FC<CategorySelectionProps> = ({
    contractsSelection,
    currentCategorySelection,
    resetForm,
}) => {
    const { t } = useTranslation('request');
    const [, meta, helpers] = useField('categorySelection');
    const { initialValues }: FormikProps<RequestInitialValues> = useFormikContext();
    const mandatoryFieldsInfo = t('category-selection.mandatory-fields-info');

    const categoriesPerProduct: { [key: string]: RequestCategory[] } = {
        [ProductTypeEN.CREDIT]: [
            'CHANGE_DURATION_MILEAGE',
            'AUTHORIZATION_DEBIT',
            'DEADLINE',
            'VEHICLE_CLAIM',
            'DEATH',
            'LOSS',
            'OTHER',
            'DOCUMENTS',
        ],
        [ProductTypeEN.BALLON_LEASING]: [
            'AUTHORIZATION_LEAVE_COUNTRY',
            'CHANGE_DURATION_MILEAGE',
            'DEADLINE',
            'AUTHORIZATION_DEBIT',
            'REQUEST_CONTRACT_TRANSFER',
            'REGISTRATION_CERTIFICATE_DUPLICATE',
            'VEHICLE_CLAIM',
            'DEATH',
            'LOSS',
            'DOCUMENTS',
            'OTHER',
        ],
        [ProductTypeEN.OPERATIVE_LEASING]: [
            'CHANGE_DURATION_MILEAGE',
            'AUTHORIZATION_LEAVE_COUNTRY',
            'TENANT',
            'REGISTRATION_CERTIFICATE_DUPLICATE',
            'VEHICLE_CLAIM',
            'DEATH',
            'LOSS',
            'DOCUMENTS',
            'OTHER',
        ],
        [ProductTypeEN.FINANCIAL_LEASE]: [
            'AUTHORIZATION_LEAVE_COUNTRY',
            'CHANGE_DURATION_MILEAGE',
            'AUTHORIZATION_DEBIT',
            'TENANT',
            'REGISTRATION_CERTIFICATE_DUPLICATE',
            'VEHICLE_CLAIM',
            'DEATH',
            'LOSS',
            'DOCUMENTS',
            'OTHER',
        ],
        [ProductTypeEN.VDL]: ['AUTHORIZATION_LEAVE_COUNTRY', 'DOCUMENTS', 'OTHER'],
        [ProductTypeEN.STAND_ALONE]: ['DOCUMENTS', 'OTHER'],
        [ProductTypeEN.OTHER]: ['OTHER'],
    };

    const getCategoriesForProduct = (): RequestCategory[] => {
        const contractEndDateWas6MonthAgo = contractsSelection.find((item) =>
            formatCpDate(item.contractEndDate).add(6, 'month').toMoment().isBefore(new Date()),
        );
        if (!contractsSelection.length || contractEndDateWas6MonthAgo) {
            return ['OTHER'];
        }

        const selectedProductTypesEN: Array<string | undefined> = uniqBy(contractsSelection, 'productTypeEN').map(
            (item) => item.productTypeEN,
        );
        const excludedProductTypesEN = [
            'CHANGE_DURATION_MILEAGE',
            'REGISTRATION_CERTIFICATE_DUPLICATE',
            'VEHICLE_CLAIM',
        ];

        // check if CHANGE_DURATION_MILEAGE category should be disabled
        const isChangeDurationMileageCategoryDisabled = (): boolean => {
            if (!selectedProductTypesEN.includes(ProductTypeEN.FINANCIAL_LEASE)) return false;

            for (const selectedContract of contractsSelection) {
                const productType = selectedContract.productType;
                const isCreditBailType = productType?.toLowerCase().includes('crédit bail');
                return isCreditBailType && !['Crédit Bail VR+VN', 'Crédit Bail VR + VO'].includes(productType);
            }

            return false;
        };

        let list: RequestCategory[] = [];

        if (selectedProductTypesEN.length === 1) {
            const selectedTypeEN = head(selectedProductTypesEN);

            // if contracts have similar types
            if (contractsSelection.length > 1) {
                list = selectedTypeEN
                    ? categoriesPerProduct[selectedTypeEN].filter((item) => !excludedProductTypesEN.includes(item))
                    : ['OTHER'];
            } else {
                if (isVDLContract(contractsSelection[0])) {
                    list = ['DOCUMENTS', 'AUTHORIZATION_LEAVE_COUNTRY', 'OTHER'];
                } else {
                    list = selectedTypeEN ? categoriesPerProduct[selectedTypeEN] : ['OTHER'];
                }
            }
        } else {
            const allowedCategories: RequestCategory[] = [];

            for (const productType of Object.keys(categoriesPerProduct)) {
                // skip when productType is not matching any selected contract product type
                if (!selectedProductTypesEN.find((item) => item === productType)) continue;

                for (const category of categoriesPerProduct[productType]) {
                    // skip when category is already added to dropdown
                    if (allowedCategories.includes(category)) continue;
                    let addItem = true;

                    // check if category is allowed for all selected contract types
                    for (const selectedProductType of selectedProductTypesEN) {
                        if (selectedProductType && !categoriesPerProduct[selectedProductType].includes(category)) {
                            addItem = false;
                        }
                    }

                    if (addItem) {
                        allowedCategories.push(category);
                    }
                }
            }

            list = allowedCategories.filter((item) => !excludedProductTypesEN.includes(item));
        }

        // remove CHANGE_DURATION_MILEAGE category if disabled because of credit bail
        if (isChangeDurationMileageCategoryDisabled()) {
            list = list.filter((item) => item !== 'CHANGE_DURATION_MILEAGE');
        }

        // If a person has unpaid amount, we should block the following functionalities.
        // https://confluence.platform.vwfs.io/display/CPFR/Display+of+unpaid+details+and+payment
        if (contractsSelection.find((item) => hasUnpaidAmmount(item))) {
            const restrictedActions = [
                'AUTHORIZATION_LEAVE_COUNTRY',
                'REGISTRATION_CERTIFICATE_DUPLICATE',
                'CHANGE_DURATION_MILEAGE',
                'TENANT',
                'REQUEST_CONTRACT_TRANSFER',
                'DEADLINE',
            ];
            list = list.filter((item) => !restrictedActions.includes(item));
        }

        return list;
    };

    const renderOptions = (): JSX.Element[] | null => {
        const categories = getCategoriesForProduct();
        if (!Array.isArray(categories) || !categories.length) {
            return null;
        }
        return categories.map((category: RequestCategory) => (
            <Combobox.Item
                testId={`contract-selection-item-${category}`}
                optionValue={t(`category-selection.options.${kebabCase(category)}`)}
                optionKey={category}
                key={category}
            >
                {t(`category-selection.options.${kebabCase(category)}`)}
            </Combobox.Item>
        ));
    };
    const defaultState = {
        value: [
            {
                value: t('category-selection.placeholder'),
                key: 'placeholder',
            },
        ],
    };

    const setValueChange = useCallback(
        (selectedCategory: ComboboxOption[]) => {
            if (selectedCategory[0]?.key !== currentCategorySelection && !contractsSelection.length) {
                return resetForm({
                    values: { ...initialValues, contractsSelection },
                });
            }
            return helpers.setValue(selectedCategory[0]?.key || initialValues.categorySelection);
        },
        [contractsSelection, currentCategorySelection], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const labelText = `${t('category-selection.label')}`;

    return (
        <>
            <p>{mandatoryFieldsInfo}</p>
            <FormField
                type="select"
                testId="category-selection"
                id={'category-selection'}
                errorMessage={
                    meta.touched &&
                    meta.error && <ErrorMessage testId="textarea-error-message">{meta.error}</ErrorMessage>
                }
                labelText={labelText}
                notion
            >
                <Combobox testId="custom-contract-select" defaultState={defaultState} onChange={setValueChange}>
                    {renderOptions()}
                </Combobox>
            </FormField>
        </>
    );
};
