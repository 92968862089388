// Do not import with ".." only, as it leads to cyclic dependencies!
import React from 'react';
import { NoConnectionNotification } from '../notifications/no-connection/NoConnectionNotification';
import { getDisplayName } from '@cp-shared-8/frontend-ui';

export type withNoConnectionHandlerProps = {
    hasError: boolean;
};

export const withNoConnectionHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withNoConnectionHandlerProps> => {
    const Wrapper: React.FC<TProps & withNoConnectionHandlerProps> = ({
        hasError,
        ...props
    }: withNoConnectionHandlerProps) =>
        hasError ? <NoConnectionNotification /> : <WrappedComponent {...(props as TProps)} />;

    Wrapper.displayName = `withNoConnectionHandler(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
