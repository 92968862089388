import * as Yup from 'yup';
import { RequestDocumentsValidatorErrors } from '../../types';
import { RequestDocumentsError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';
// import { RequestCategory, RequestDocumentType } from '../../../apis/services/types/request';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestDocumentsFormType = {
    email: string;
    phonenumber: string | undefined;
    documentType: string;
    invoiceMonth: string | undefined;
    invoiceYear: string | undefined;
    desiredYear: string | undefined;
    desiredTaxStatus: string | undefined;
    periodStartDate: string | undefined;
    periodEndDate: string | undefined;
    exitDate: string | undefined;
    returnDate: string | undefined;
    countryOfDestination: string | undefined;
    reason: string | undefined;
};

export const requestDocumentsCoreSchema = (
    errors: RequestDocumentsValidatorErrors = RequestDocumentsError,
): Yup.SchemaOf<RequestDocumentsFormType> =>
    Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        documentType: Yup.string().required(errors.documentType.required),
        invoiceMonth: Yup.string().when('documentType', {
            is: 'Duplicata de facture de loyer',
            then: Yup.string().required(errors.invoiceMonth.required),
            otherwise: Yup.string(),
        }),
        invoiceYear: Yup.string().when('documentType', {
            is: 'Duplicata de facture de loyer',
            then: Yup.string().required(errors.invoiceYear.required),
            otherwise: Yup.string(),
        }),
        desiredYear: Yup.string().when('documentType', {
            is: 'Etats fiscaux - TVS',
            then: Yup.string()
                .required(errors.desiredYear.required)
                .test('valid-invoice-month', errors.desiredYear.invalidYear, (value) => {
                    // Custom validation function to check if the year is after 2000
                    if (!value) {
                        return true;
                    }

                    const year = parseInt(value);
                    return year >= 2000;
                }),
            otherwise: Yup.string(),
        }),
        desiredTaxStatus: Yup.string().when('documentType', {
            is: 'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
            then: Yup.string().required(errors.desiredTaxStatus.required),
            otherwise: Yup.string(),
        }),
        periodStartDate: Yup.string().when('documentType', {
            is: 'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
            then: Yup.string().required(errors.periodStartDate.required),
            otherwise: Yup.string(),
        }),
        periodEndDate: Yup.string().when('documentType', {
            is: 'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
            then: Yup.string().required(errors.periodEndDate.required),
            otherwise: Yup.string(),
        }),
        exitDate: Yup.string().when('documentType', {
            is: 'Autorisation de sortie du territoire',
            then: Yup.string().required(errors.exitDate.required),
            otherwise: Yup.string(),
        }),
        returnDate: Yup.string().when('documentType', {
            is: 'Autorisation de sortie du territoire',
            then: Yup.string().required(errors.returnDate.required),
            otherwise: Yup.string(),
        }),
        countryOfDestination: Yup.string().when('documentType', {
            is: 'Autorisation de sortie du territoire',
            then: Yup.string().required(errors.countryOfDestination.required),
            otherwise: Yup.string(),
        }),
        reason: Yup.string().when('documentType', {
            is: 'Autorisation de sortie du territoire',
            then: Yup.string().required(errors.reason.required),
            otherwise: Yup.string(),
        }),
    });

export const requestDocumentsValidationSchema = (
    field: string,
    errors: RequestDocumentsValidatorErrors = RequestDocumentsError,
): Yup.SchemaOf<RequestDocumentsFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'DOCUMENTS';
        },
        then: requestDocumentsCoreSchema(errors),
    });
};
