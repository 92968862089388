import { Faq, MyProfileData } from '@cp-fr/common';
import { FaqCMS, TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { FaqLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile';
import React from 'react';
import { FaqUi } from '../ui';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';
import { ContentSection } from '@vwfs-bronson/bronson-react';

type FaqPrivateProps = {
    faq?: Faq;
    myProfile?: MyProfileData;
};

export const FaqPrivate: React.FC<FaqPrivateProps> = ({ faq, myProfile }) => {
    const { data: myProfileData, isLoading, loadingError } = useMyProfile();
    const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

    if (!faq?.content) return null;

    const isSMECustomer = () => {
        return myProfileData?.identification?.customerType
            ? myProfileData?.identification?.customerType !== 'Retail'
            : false;
    };

    const getFaqContent = (): FaqCMS => (isSMECustomer() ? faq.content.privateSme : faq.content.privateRetail);

    return (
        <>
            <FaqWithHandlers
                isLoading={isLoading}
                faqTeaser={faq.teaser}
                faqContent={getFaqContent()}
                hasError={!!loadingError}
                loadingPlaceholder={<FaqLoadingPlaceholder />}
            />
            <ContentSection pageWrapSize="medium">
                <CustomerHappinessIndex
                    firstName={myProfile?.identification?.data?.name}
                    lastName={myProfile?.identification?.data?.surname}
                    email={myProfile?.contactDetails?.email}
                    customType={TrustedShopsIntegrationCustomType.CUSTOMERPORTAL_FAQ}
                />
            </ContentSection>
        </>
    );
};
