import { Contract, getContractDetailsEndpoint } from '@cp-fr/common';
import { DefaultBusinessMarketApiError, DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { MyProfileRetailMock } from 'components/my-profile';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import {
    ActiveCreditContractWithAllValues,
    ActiveCreditContractWithMissingValues,
    InactiveCreditContractWithAllValues,
    InactiveCreditContractWithMissingValues,
} from '../ExampleData';
import { ContractsMock } from './contractsMock';
import { ContractDetailsWithAllValues, ContractDetailsWithMissingValues } from './ExampleData';

const mockOptions: MockOptions<DefaultMarketApiErrors> = {
    Success: {
        status: 200,
        responseBody: ContractDetailsWithAllValues,
        delay: 1000,
    },
    'With missing values': {
        status: 200,
        responseBody: ContractDetailsWithMissingValues,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractDetailsMock = (contract: Contract): EndpointMockDescription<DefaultMarketApiErrors> => {
    return {
        name: 'Financial Details Response',
        method: 'get',
        defaultMockOption: 'Success',
        mockOptions,
        url: getContractDetailsEndpoint(contract.contractId),
    };
};

function mockContractDetailsResponse(): void {
    const ContractDetailsMocks = [
        ContractDetailsMock(InactiveCreditContractWithAllValues),
        ContractDetailsMock(InactiveCreditContractWithMissingValues),
        ContractDetailsMock(ActiveCreditContractWithMissingValues),
        ContractDetailsMock(ActiveCreditContractWithAllValues),
    ];
    setupMockResponses(CpDataApi, [ContractsMock, MyProfileRetailMock, ...ContractDetailsMocks]);
}

export const ContractDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockContractDetailsResponse();
    return <div>{storyFn()}</div>;
};
