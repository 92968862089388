import * as Yup from 'yup';
import { RequestChangeDurationMileageValidatorErrors } from '../../types';
import { RequestChangeDurationMileageError } from '../../messages';
import { ContractSelection, RequestCategory } from '../../../apis/services/types/request';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';
import { ProductTypeEN } from '../../../apis';

export type RequestChangeDurationMileageFormType = {
    email: string;
    phonenumber: string | undefined;
    totalDuration: string | undefined;
    desiredMileage: string | undefined;
    desiredTiresSummer: string | undefined;
    desiredTiresWinter: string | undefined;
};

export const requestChangeDurationMileageCoreSchema = (
    productType: string | undefined,
    productTypeEN: ProductTypeEN | undefined = ProductTypeEN.OTHER,
    errors: RequestChangeDurationMileageValidatorErrors = RequestChangeDurationMileageError,
): Yup.SchemaOf<RequestChangeDurationMileageFormType> => {
    return Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        totalDuration: Yup.string().when('email', {
            is: (emailValue: string) => emailValue,
            then: Yup.string()
                .when('desiredMileage', {
                    is: (val: string) => productTypeEN === ProductTypeEN.OPERATIVE_LEASING && !val,
                    then: Yup.string().required(errors.totalDuration.required),
                })
                .when('_', {
                    is: () => productTypeEN === ProductTypeEN.CREDIT,
                    then: Yup.string().required(errors.totalDuration.required),
                }),
        }),
        desiredMileage: Yup.string().when('email', {
            is: (emailValue: string) => emailValue,
            then: Yup.string()
                .when('totalDuration', {
                    is: (val: string) => productTypeEN === ProductTypeEN.OPERATIVE_LEASING && !val,
                    then: Yup.string().required(errors.desiredMileage.required),
                })
                .when('_', {
                    is: () => productTypeEN === ProductTypeEN.BALLON_LEASING,
                    then: Yup.string().required(errors.desiredMileage.required),
                })
                .when('_', {
                    is: () =>
                        productTypeEN === ProductTypeEN.FINANCIAL_LEASE &&
                        productType &&
                        ['crédit bail vr+vn', 'crédit bail vr + vo'].includes(productType.toLocaleLowerCase()),
                    then: Yup.string().required(errors.desiredMileage.required),
                    otherwise: Yup.string(),
                }),
        }),
        desiredTiresSummer: Yup.string(),
        desiredTiresWinter: Yup.string(),
        comment: Yup.string(),
    });
};

export const requestChangeDurationMileageValidationSchema = (
    field: string[],
    errors: RequestChangeDurationMileageValidatorErrors = RequestChangeDurationMileageError,
): Yup.SchemaOf<RequestChangeDurationMileageFormType | {}> => {
    let productType: string | undefined;
    let productTypeEN: ProductTypeEN | undefined;

    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory, contractsSelection: ContractSelection[]): boolean => {
            if (Array.isArray(contractsSelection) && contractsSelection.length === 1) {
                const contract = contractsSelection[0];
                productType = contract.productType;
                productTypeEN = contract.productTypeEN;
            } else {
                productTypeEN = ProductTypeEN.OTHER;
            }
            return requestCategory === 'CHANGE_DURATION_MILEAGE';
        },
        then: Yup.lazy(() => requestChangeDurationMileageCoreSchema(productType, productTypeEN, errors)),
    });
};
