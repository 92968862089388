import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-8/frontend-ui';
import {
    changeAddressPath,
    changeBillingAddressPath,
    changeContactDetailsPath,
    changeIbanPath,
    changeMarketingSettingsPath,
    civicToolCookiePolicyPath,
    contactDetailsCheckPagePath,
    contractEndOptionsPath,
    cookiePolicyPath,
    dashboardPagePath,
    earlySettlementPagePath,
    faqPagePath,
    forbiddenPagePath,
    landingPagePath,
    legalNoticePath,
    myProfilePagePath,
    myRequestsPagePath,
    myRequestsPagePathForContact,
    notAuthorizedPagePath,
    notFoundPagePath,
    partialEarlySettlementPagePath,
    privacyPolicyPath,
    registrationPagePath,
    totalEarlySettlementPagePath,
    parkingPagePath,
    earlyReturnPenaltyPagePath,
    parkingLinkingResultPath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import {
    ContractEndOptionsPage,
    CookiePolicyPage,
    DashboardPage,
    EarlySettlementPage,
    FaqPage,
    ForbiddenPage,
    LandingPage,
    LegalNoticePage,
    MasterPage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    RegistrationPage,
    RequestPage,
    ParkingPage,
    EarlyReturnPenaltyPage,
} from 'pages';
import { ContactDetailsCheckPage } from 'pages/contact-details-check-page';
import { PartialEarlySettlementPage } from 'pages/partial-early-settlement-page/PartialEarlySettlementPage';
import { TotalEarlySettlementPage } from 'pages/total-early-settlement-page/TotalEarlySettlementPage';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthenticatedRoute, AuthorizedRoute } from './auth';
import { LastLocationProvider } from 'react-router-last-location';
import { AxiosConfiguration } from './auth/AxiosConfiguration';
import { PrivacyPolicyPage } from './pages/privacy-policy-page';
import { history } from './store/rootReducer';
import { ParkingLinkingResult } from './components/parking/parking-linking-result/ParkingLinkingResult';
import { payByPhoneEnabled } from './config';

const App: React.FC = () => (
    <div className="App">
        <Suspense fallback={<Spinner center />}>
            <AuthenticationProvider loadingComponent={<Spinner center />}>
                <AutoLogout idleTimeInMinutes={180} redirectUri={window.location.origin + landingPagePath()}>
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <ScrollToTopOnNavigation
                                exceptions={[
                                    { from: /\S*/, to: new RegExp(changeIbanPath('[\\w-]+')) },
                                    { from: new RegExp(changeIbanPath('[\\w-]+')), to: dashboardPagePath() },
                                    { from: myProfilePagePath(), to: changeContactDetailsPath() },
                                    { from: changeContactDetailsPath(), to: myProfilePagePath() },
                                    { from: myProfilePagePath(), to: changeAddressPath() },
                                    { from: changeAddressPath(), to: myProfilePagePath() },
                                    { from: myProfilePagePath(), to: changeBillingAddressPath() },
                                    { from: changeBillingAddressPath(), to: myProfilePagePath() },
                                    { from: changeMarketingSettingsPath(), to: myProfilePagePath() },
                                    { from: myProfilePagePath(), to: changeMarketingSettingsPath() },
                                ]}
                            />
                            <LastLocationProvider>
                                <MasterPage>
                                    <Switch>
                                        <Route exact path={landingPagePath()}>
                                            <LandingPage />
                                        </Route>
                                        <Route path={faqPagePath()}>
                                            <FaqPage />
                                        </Route>
                                        <Route path={cookiePolicyPath()}>
                                            <CookiePolicyPage />
                                        </Route>
                                        <Route path={civicToolCookiePolicyPath()}>
                                            <Redirect to={cookiePolicyPath()} />
                                        </Route>
                                        <Route path={legalNoticePath()}>
                                            <LegalNoticePage />
                                        </Route>
                                        <Route path={privacyPolicyPath()}>
                                            <PrivacyPolicyPage />
                                        </Route>
                                        <AuthorizedRoute path={dashboardPagePath()}>
                                            <DashboardPage />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute path={myProfilePagePath()}>
                                            <MyProfilePage />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute path={myRequestsPagePath()}>
                                            <RequestPage />
                                        </AuthorizedRoute>
                                        {payByPhoneEnabled && (
                                            <AuthorizedRoute path={parkingPagePath()}>
                                                <ParkingPage />
                                            </AuthorizedRoute>
                                        )}
                                        {payByPhoneEnabled && (
                                            <AuthorizedRoute path={parkingLinkingResultPath()}>
                                                <ParkingLinkingResult />
                                            </AuthorizedRoute>
                                        )}
                                        <AuthorizedRoute path={myRequestsPagePathForContact()}>
                                            <RequestPage />
                                        </AuthorizedRoute>
                                        <AuthenticatedRoute path={registrationPagePath()}>
                                            <RegistrationPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={contactDetailsCheckPagePath()}>
                                            <ContactDetailsCheckPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={earlySettlementPagePath()}>
                                            <EarlySettlementPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={earlyReturnPenaltyPagePath()}>
                                            <EarlyReturnPenaltyPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={partialEarlySettlementPagePath()}>
                                            <PartialEarlySettlementPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={totalEarlySettlementPagePath()}>
                                            <TotalEarlySettlementPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={contractEndOptionsPath()}>
                                            <ContractEndOptionsPage />
                                        </AuthenticatedRoute>
                                        <Route path={forbiddenPagePath()}>
                                            <ForbiddenPage />
                                        </Route>
                                        <Route path={notAuthorizedPagePath()}>
                                            <NotAuthorizedPage />
                                        </Route>
                                        <Route path={notFoundPagePath()}>
                                            <NotFoundPage />
                                        </Route>
                                        <Route path="*">
                                            <Redirect to={notFoundPagePath()} />
                                        </Route>
                                    </Switch>
                                </MasterPage>
                            </LastLocationProvider>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AutoLogout>
            </AuthenticationProvider>
        </Suspense>
    </div>
);

export default App;
