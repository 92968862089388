import { Base64File } from '../files';

export type FinancialDetails = {
    amountBorrowed?: number;
    term?: number;
    contractMileage?: number;
    monthlyPaymentTTC?: number;
    monthlyPaymentHT?: number;
    collectionDate?: number;
    rate?: number;
    taegRate?: number;
    iban?: string;
    ibanHolder?: string;
    firstDatePayment?: string;
    lastDatePayment?: string;
    lastPaymentInclTVA?: number;
    lastPaymentExclTVA?: number;
    returnBackVehicleDate?: string;
    numberOfServices?: number;
    paymentMethod?: string;
    paymentDeadline?: string;
    _links?: FinancialDetailsLinks;
};

export type FinancialDetailsLinks = {
    changeIban?: string;
};

export interface IbanChange {
    iban: string;
    currentIban: string;
    ibanHolder: string;
    email: string;
    allContracts: boolean;
    file: Base64File;
    attempt: number;
}
export interface PaymentDayChange {
    newPaymentDay: number;
}

export type IbanSuccessMessage = 'SUCCESSFUL_REQUEST' | 'REQUEST_SAVED';
export type IbanProcessingMessage = 'REQUEST_PROCESSING_IN_PROGRESS';
export type IbanMessage = IbanSuccessMessage | IbanProcessingMessage;

export type IbanChangeProcessingResult = {
    requestId: string;
};

export type IbanChangeResult = {
    message: IbanMessage;
    effectDate: string;
};

export type IbanError = 'BFF_API_ERROR' | 'CHECK_FAILURE';

export const MAX_IBAN_CHANGE_ATTEMPTS = 2;
