import { Contract, ProductTypeEN, hasUnpaidAmmount, isVDLContract } from '@cp-fr/common';
import { ContractDetails as TContractDetails } from '@cp-fr/common/src/apis/services/types/contracts';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { ContractActions, Skeleton, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { useMyProfile } from 'components/my-profile';
import { ContactDetailsCheckPendingNotification } from 'components/my-profile/address-section/contact-details-change-pending-notification';
import { earlyReturnPenaltyPagePath, earlySettlementPagePath } from 'components/navigation/paths';
import moment, { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getFirstEligibleDateForEarlyReturnPenalty } from 'utils/early-return-penalty-utils';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { ContractDetailsUi } from './ui/ContractDetailsUi';

export const ContractDetails: React.FC<{
    contract: Contract & TContractDetails;
    isContactInformationChangePending: boolean;
}> = ({ contract, isContactInformationChangePending }) => {
    const { data: myProfileData, isLoading: isLoadingProfile, loadingError: profileLoadingError } = useMyProfile();
    const { t } = useTranslation('contracts');
    const history = useHistory();
    const { onAction } = useAnalyticsActionTracker('onOpenSuspendInstallment');
    const ContractDetailsWithHandlers = withLoadingAndNoConnectionHandler(ContractDetailsUi);
    const actionItems = [];

    const isSMECustomer = () => {
        return myProfileData?.identification?.customerType
            ? myProfileData?.identification?.customerType !== 'Retail'
            : false;
    };

    const isCreditContract = contract.productTypeEN === ProductTypeEN.CREDIT;
    const isBalloonLeasing = contract.productTypeEN === ProductTypeEN.BALLON_LEASING;
    const isFinancialLeasing = contract.productTypeEN === ProductTypeEN.FINANCIAL_LEASE;
    const isOperativeLeasing = contract.productTypeEN === ProductTypeEN.OPERATIVE_LEASING;
    const lastsLongerThan12Months = formatCpDate(contract.contractStartDate)
        .add(12, 'month')
        .toMoment()
        .isBefore(new Date());
    const firstDateForEarlyReturnPenalty: Moment | undefined = getFirstEligibleDateForEarlyReturnPenalty(contract);
    const isReturnPenaltyDisabled =
        formatCpDate(contract.contractEndDate).toMoment().month() === moment().month() ||
        formatCpDate(contract.contractEndDate).toMoment().isBefore(new Date()) ||
        !firstDateForEarlyReturnPenalty ||
        isVDLContract(contract) ||
        hasUnpaidAmmount(contract);

    if (
        contract.isActive &&
        (isCreditContract ||
            (isFinancialLeasing && lastsLongerThan12Months) ||
            (isBalloonLeasing && lastsLongerThan12Months))
    ) {
        actionItems.push({
            iconName: 'semantic-calculator',
            pageUrl: earlySettlementPagePath(contract.contractId),
            label: contract?.productType?.toLowerCase()?.includes('crédit bail')
                ? t('contract-actions.financial-lease-early-settlement')
                : t('contract-actions.early-settlement'),
            onClick: () => onAction(),
        });
    }

    if (contract.isActive && isOperativeLeasing && lastsLongerThan12Months) {
        actionItems.push({
            iconName: 'semantic-calculator',
            label: t('contract-actions.early-return-penalty'),
            onClick: () => {
                history.push(earlyReturnPenaltyPagePath(contract.contractId));
                onAction();
            },
            disabled: isReturnPenaltyDisabled,
        });
    }

    return (
        <>
            <div className="u-pb-small u-mt-xxsmall">
                {isContactInformationChangePending ? (
                    <ContactDetailsCheckPendingNotification />
                ) : (
                    <ContractActions contractActionItems={actionItems} />
                )}
            </div>
            <ContractDetailsWithHandlers
                isLoading={isLoadingProfile}
                contract={contract}
                isSMECustomer={isSMECustomer()}
                hasError={!!profileLoadingError}
                isContactInformationChangePending={isContactInformationChangePending}
                loadingPlaceholder={<Skeleton height={30} count={4} className="u-mt-small" />}
            />
        </>
    );
};
