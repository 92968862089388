import { getIbanStatusEndpoint } from '@cp-fr/common';
import { Contract } from '@cp-fr/common/src/apis/services/types/contracts';
import { NotificationCenter } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type NotificationCenterProps = {
    contractNotification?: Contract;
    contractNotificationForLessOfSixMonths?: Contract;
    contractNotificatioForLessOneMonth?: Contract;
    contractNotificatioWithNewVehiculeForLessOneMonth?: Contract;
    contractNotificationForLessTwoMonths?: Contract;
    myProfileNotification?: boolean;
    porductType?: boolean;
};

type NotificationItemProps = {
    id: number;
    title: string;
    content?: string | React.ReactNode;
    link?: string;
    externalLink?: string;
    read: boolean;
};

export const NotificationsCenterUi: React.FC<NotificationCenterProps> = ({
    contractNotification,
    myProfileNotification,
    contractNotificationForLessOfSixMonths,
    contractNotificatioForLessOneMonth,
    contractNotificatioWithNewVehiculeForLessOneMonth,
    contractNotificationForLessTwoMonths,
    porductType,
}) => {
    const { t } = useTranslation('notification-center');
    const history = useHistory();
    const [ibanStatus, setIbanStatus] = useState<boolean>(false);

    useEffect(() => {
        CpDataApi.get(getIbanStatusEndpoint()).then((response) => {
            if (response.data.requestCustomerStatus === 'IN_PROGRESS') {
                setIbanStatus(true);
            }
        });
    }, []);

    const staticNotifications: NotificationItemProps[] = [
        ...(myProfileNotification
            ? [
                  {
                      id: 1,
                      title: t('incorrectAddress.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t(`incorrectAddress.content`) }} />,
                      link: t('incorrectAddress.link'),
                      read: false,
                  },
              ]
            : []),
        ...(contractNotification
            ? [
                  {
                      id: 2,
                      title: t('contractEnds.title'),
                      content: t('contractEnds.content'),
                      link: `${t('contractEnds.link')}/${contractNotification.contractId}`,
                      read: false,
                  },
              ]
            : []),
        ...(ibanStatus
            ? [
                  {
                      id: 3,
                      title: t('ibanStatus.title'),
                      content: t('ibanStatus.content'),
                      link: t('ibanStatus.link'),
                      read: false,
                  },
              ]
            : []),
        ...(contractNotificationForLessOfSixMonths
            ? [
                  {
                      id: 4,
                      title: t('contractEndsForLessOfSixMonths.title'),
                      read: false,
                  },
              ]
            : []),
        ...(porductType && contractNotificatioForLessOneMonth
            ? [
                  {
                      id: 5,
                      title: t('contractEndsForLessOneMonth.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t('contractEndsForLessOneMonth.content') }} />,
                      read: false,
                  },
              ]
            : []),
        ...((!porductType && contractNotificatioForLessOneMonth) || contractNotificatioWithNewVehiculeForLessOneMonth
            ? [
                  {
                      id: 6,
                      title: t('contractEndsWithNewVehiculeForLessOneMonth.title'),
                      content: t('contractEndsWithNewVehiculeForLessOneMonth.content'),
                      read: false,
                  },
              ]
            : []),
        ...(contractNotificationForLessTwoMonths
            ? [
                  {
                      id: 7,
                      title: t('contractEndsForLessTwoMonths.title'),
                      content: t('contractEndsForLessTwoMonths.content'),
                      read: false,
                  },
              ]
            : []),
    ];

    return (
        <NotificationCenter notifications={staticNotifications}>
            {(notification: NotificationItemProps): JSX.Element => (
                <NotificationCenter.Item
                    statusReadLabel=""
                    markAsReadLabel={t('read')}
                    markAsUnreadLabel={t('unread')}
                    onClick={(): void => {
                        if (notification.link) {
                            history.push(notification.link);
                        }
                        if (notification.externalLink) {
                            window.open(notification.externalLink, '_blank');
                        }
                    }}
                >
                    {notification.content && <p>{notification.content}</p>}
                </NotificationCenter.Item>
            )}
        </NotificationCenter>
    );
};
