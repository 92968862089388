import { ContractInfo } from '@cp-fr/common';
import { Combobox, ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { FormikProps, useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestInitialValues } from '../initialValues';
import { ComboboxOption } from '../../../../types/bronson-react';
import { head } from 'lodash';

export type ContractSelectionProps = {
    contracts?: ContractInfo[];
    currentContracts: ContractInfo[];
    isSMECustomer?: boolean;
};

export const ContractSelection: React.FC<ContractSelectionProps> = ({ contracts, currentContracts, isSMECustomer }) => {
    const [, meta, helpers] = useField('contractsSelection');
    const [authorizationDebitField, , authorizationDebitHelpers] = useField('authorizationDebit');
    const { initialValues, setValues }: FormikProps<RequestInitialValues> = useFormikContext();
    const { t } = useTranslation('request');
    const setValueChange = useCallback((selectedContractsOptions: ComboboxOption[]): void => {
        if (!Array.isArray(contracts) || !contracts.length) {
            return;
        }
        if (!selectedContractsOptions.length) {
            setValues({
                ...initialValues,
                contractsSelection: [],
                authorizationDebit: {
                    ...initialValues.authorizationDebit,
                    authorizes: initialValues.authorizationDebit.authorizes,
                },
            });
        }
        let selectedContracts: ContractInfo[] = [];
        for (const selected of selectedContractsOptions) {
            selectedContracts = [
                ...selectedContracts,
                ...contracts.filter((contract) => contract.contractNumber === selected.key),
            ];
        }

        // set authorizes as contract number when only one contract is selected
        if (selectedContracts && selectedContracts.length === 1) {
            const selectedContractNumber = head(selectedContracts)?.contractNumber;
            authorizationDebitHelpers.setValue({
                ...authorizationDebitField.value,
                authorizes: selectedContractNumber,
            });
        }

        return helpers.setValue(selectedContracts || initialValues.contractsSelection);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!contracts) {
        return null;
    }

    const options = [
        ...contracts.filter((contract) => contract.isActive),
        ...contracts.filter((contract) => !contract.isActive),
    ]
        .map(({ carModel, productType, licensePlate, contractNumber, isActive }) => {
            if (!contractNumber || !productType) return null;

            const getProductTypeValue = (): string =>
                isActive ? productType : t(`contract-selection.contract-expired`);
            const carInformation = `${carModel}, ${licensePlate}`;

            return (
                <Combobox.Item
                    testId={`contracts-selection-item-${contractNumber}`}
                    optionValue={contractNumber}
                    optionKey={contractNumber}
                    key={contractNumber}
                >
                    <div>
                        <b>{contractNumber}</b>
                        <br />
                        <b>{getProductTypeValue()}</b>
                        <br />
                        {carModel && licensePlate && <span>{carInformation}</span>}
                    </div>
                </Combobox.Item>
            );
        })
        .filter(Boolean);

    const defaultState = {
        value: currentContracts.map((contract) => ({
            value: contract.contractNumber,
            key: contract.contractNumber,
        })),
    };

    const label = `${t('contract-selection.label')}`;

    return (
        <FormField
            type="select"
            testId={'contract-selection'}
            id={'contract-selection'}
            errorMessage={
                meta.touched &&
                meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
            }
            labelText={label}
            notion
        >
            <p>{t('contract-selection.header')}</p>
            <Combobox
                testId={'custom-contract-select'}
                onChange={setValueChange}
                defaultState={defaultState}
                multiple={isSMECustomer}
            >
                {options}
            </Combobox>
        </FormField>
    );
};
