import React from 'react';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker, useAuthentication } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LandingPageUi } from './ui';
import { useLandingPage } from './useLandingPage';
import { useLogin } from '../../auth/useLogin';
import { dashboardPagePath } from '../navigation/paths';
import { useHistory } from 'react-router-dom';

const LandingPageWithHandlers = withLoadingAndNoConnectionHandler(LandingPageUi);

export const LandingPage: React.FC = () => {
    const { cmsContent: landingPage, isLoading, loadingError } = useLandingPage();
    const { isAuthenticated } = useAuthentication();
    const history = useHistory();

    const login = useLogin();
    const { onAction } = useAnalyticsActionTracker('login');
    useAnalyticsPageViewTracker('landingPage', true);

    const loginHandler = async (): Promise<void> => {
        onAction();
        return await login();
    };

    const loginHandlerAuthenticated = async (): Promise<void> => {
        onAction();
        history.push(dashboardPagePath());
    };

    return (
        <LandingPageWithHandlers
            isLoading={isLoading}
            landingPage={landingPage}
            isAuthenticated={isAuthenticated}
            hasError={!!loadingError}
            clickHandler={isAuthenticated ? loginHandlerAuthenticated : loginHandler}
        />
    );
};
