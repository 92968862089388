import { ProductTypeEN } from '@cp-fr/common';

export enum CustomerProfile {
    PRIVATE = 'Private',
    PRIVATE_SME = 'Private/SME',
    SME = 'SME',
    EMPLOYEES = 'Employees',
}
export const getCustomerProfile = (productType?: ProductTypeEN): CustomerProfile | undefined => {
    if (productType) {
        switch (productType) {
            case ProductTypeEN.CREDIT:
                return CustomerProfile.PRIVATE;
            case ProductTypeEN.BALLON_LEASING:
                return CustomerProfile.PRIVATE;
            case ProductTypeEN.OPERATIVE_LEASING:
                return CustomerProfile.SME;
            case ProductTypeEN.FINANCIAL_LEASE:
                return CustomerProfile.SME;
            case ProductTypeEN.STAND_ALONE:
                return CustomerProfile.PRIVATE_SME;
            case ProductTypeEN.VDL:
                return CustomerProfile.EMPLOYEES;
            case ProductTypeEN.OTHER:
                return CustomerProfile.PRIVATE;
            default:
                break;
        }
    }
};

export const valueAvailable = (value?: number) => !!value && value > 1;
