import React from 'react';
import { NotificationsCenterUi } from './ui/NotificationsCenterUi';
import { useMyProfile } from 'components/my-profile';
import { Contract } from '@cp-fr/common';
import { useContracts } from '../contracts/useContracts';

export const NotificationsCenter: React.FC = () => {
    const { data: myProfileData } = useMyProfile();
    const { data: contracts } = useContracts();

    const checkContractNotification = (property: string): Contract | undefined => {
        return contracts?.find((contract) => {
            return contract[property as keyof typeof contract];
        });
    };

    const checkContractType = (type: string): boolean => {
        return !!contracts?.find((contract) => {
            return contract['productType']?.includes(type);
        });
    };
    return (
        <NotificationsCenterUi
            porductType={checkContractType('LLD Loueur')}
            myProfileNotification={myProfileData?.identification?.data.isEligibleNpaiNotification}
            contractNotification={checkContractNotification('isEligibleRenewalContractNotification')}
            contractNotificationForLessOfSixMonths={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOfSixMonths',
            )}
            contractNotificatioForLessOneMonth={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOneMonth',
            )}
            contractNotificatioWithNewVehiculeForLessOneMonth={checkContractNotification(
                'isEligibleRenewalContractWithNewVehiculeNotificationForLessOneMonth',
            )}
            contractNotificationForLessTwoMonths={checkContractNotification(
                'isEligibleRenewalFinancialLeasingContractNotificationForLessTwoMonths',
            )}
        />
    );
};
