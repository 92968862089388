import { BundledProduct } from './bundled-products';
import { ContractParty } from './contract-parties';
import { FinancialDetails } from './financial-details';
import { VehicleDetails } from './vehicle-details';

export enum ProductTypeEN {
    CREDIT = 'Credit',
    BALLON_LEASING = 'Rental with purchase option',
    OPERATIVE_LEASING = 'Long Term Leasing',
    FINANCIAL_LEASE = 'Financial lease',
    VDL = 'VDL',
    LONG_TERM_LEASING = 'Long Term Leasing', // For VDL
    STAND_ALONE = 'Stand alone',
    OTHER = 'Other',
}

export enum ContractEndOptionsEN {
    RENEWAL = 'RENEWAL',
    REFINANCING = 'REFINANCING',
    PAYMENT = 'PAYMENT',
    RETURN = 'RETURN_CAR',
}

export type Contract = {
    isActive: boolean;
    /**
     * Encrypted contract number displayed in urls in the FE
     */
    contractId: string;
    contractNumber: string;
    productType?: string;
    productTypeEN?: ProductTypeEN;
    carModel?: string;
    brand?: string;
    licensePlate?: string;
    contractStartDate: string;
    contractEndDate: string;
    returnBackVehicleDate: string;
    _links?: ContractLinks;
    isEligibleRenewalContractNotification?: boolean;
    isEligibleRenewalContractNotificationForLessOfSixMonths?: boolean;
    isEligibleRenewalContractNotificationForLessOneMonth?: boolean;
    isEligibleRenewalContractWithNewVehiculeNotificationForLessOneMonth?: boolean;
    isEligibleRenewalFinancialLeasingContractNotificationForLessTwoMonths?: boolean;
    amountUnpaidNotPaid?: number;
};

export type ContractsError = 'NOT_FOUND';

export type ContractLinks = {
    contractDetails?: string;
};

export type ContractDetails = {
    financial: FinancialDetails;
    vehicle: VehicleDetails;
    contractParties: ContractParty[];
    bundledProducts: BundledProduct[];
};

export type ContractEndOptionsDetails = {
    email: string;
    endOption: ContractEndOptionsEN;
};

export type isCustomerType = {
    isPrivateCustomer: boolean;
    isPMCustomer: boolean;
};
