import * as Yup from 'yup';
import { RequestContractTransferValidatorErrors } from '../../types';
import { RequestContractTransferError } from '../../messages';
import {
    RequestCategory,
    EmploymentStatus,
    SocioprofessionalCategories,
    blueCategories,
} from '../../../apis/services/types/request';
import { fileValidation } from '../FileValidator';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestContractTransferFormType = {
    email: string;
    phonenumber: string | undefined;
    buyerNames: string;
    buyerPhonenumber: string | undefined;
    buyerEmail: string;
    requestReason: string | undefined;
    requestReasonDescription: string | undefined;
    employmentStatus: string;
};

export type RequestContractTransferBackendFormType = RequestContractTransferFormType & {
    files: {
        base64: string | undefined;
        filename: string | undefined;
        mimeType: string | undefined;
        size: number | undefined;
    }[];
};

export type RequestContractTransferFrontendFormType = RequestContractTransferFormType & {
    files: {
        signedConfirmation?: string;
        buyerIdentity?: string;
        bankDetails?: string;
        payslips?: string;
        taxNotice?: string;
        registrationDoc?: string;
        proofOfAddress?: string;
        buyerProofOfAddress?: string;
        accomodationCertificate?: string;
        indentityDoc?: string;
    };
};

export const requestContractTransferCoreSchema = (
    errors: RequestContractTransferValidatorErrors = RequestContractTransferError,
): Yup.SchemaOf<RequestContractTransferBackendFormType> => {
    return Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        buyerNames: Yup.string().trim().required(errors.buyerNames.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phonenumber.mustBePhoneNumber),
        buyerPhonenumber: Yup.string()
            .trim()
            .min(10, errors.buyerPhonenumber.mustBePhoneNumber)
            .required(errors.buyerPhonenumber.required),
        buyerEmail: Yup.string().trim().email(errors.buyerEmail.mustBeEmail).required(errors.buyerEmail.required),
        requestReason: Yup.string().trim().required(errors.requestReason.required),
        requestReasonDescription: Yup.string().when('requestReason', {
            is: (val: string) => {
                return val === 'Autre, précisez';
            },
            then: Yup.string().trim().required(errors.requestReasonDescription.required),
        }),
        employmentStatus: Yup.string().trim().required(errors.employmentStatus.required),
        files: Yup.array()
            .of(
                Yup.object().shape({
                    base64: Yup.string(),
                    filename: Yup.string(),
                    mimeType: Yup.string(),
                    size: Yup.number(),
                }),
            )
            .min(1, errors.files.accomodationCertificate.required),
    });
};

export const requestContractTransferFrontendValidationSchema = (
    errors: RequestContractTransferValidatorErrors = RequestContractTransferError,
): Yup.SchemaOf<RequestContractTransferFrontendFormType | {}> => {
    const baseFilesValidation = {
        signedConfirmation: fileValidation(
            errors.files.signedConfirmation.maxLength,
            errors.files.signedConfirmation.required,
        ),
        buyerIdentity: fileValidation(errors.files.buyerIdentity.maxLength, errors.files.buyerIdentity.required),
        bankDetails: fileValidation(errors.files.bankDetails.maxLength, errors.files.bankDetails.required),
        payslips: fileValidation(errors.files.payslips.maxLength),
        taxNotice: fileValidation(errors.files.taxNotice.maxLength, errors.files.taxNotice.required),
        registrationDoc: fileValidation(errors.files.registrationDoc.maxLength, errors.files.registrationDoc.required),
    };
    return requestContractTransferCoreSchema(errors).shape({
        files: Yup.object()
            .when('employmentStatus', {
                is: (val: EmploymentStatus) => val === 'Propriétaire' || val === 'Locataire',
                then: Yup.object().shape({
                    ...baseFilesValidation,
                    proofOfAddress: fileValidation(
                        errors.files.proofOfAddress.maxLength,
                        errors.files.proofOfAddress.required,
                    ),
                }),
            })
            .when('employmentStatus', {
                is: (val: EmploymentStatus) => val === 'Logé à titre gratuit',
                then: Yup.object().shape({
                    ...baseFilesValidation,
                    buyerProofOfAddress: fileValidation(
                        errors.files.buyerProofOfAddress.maxLength,
                        errors.files.buyerProofOfAddress.required,
                    ),
                    accomodationCertificate: fileValidation(
                        errors.files.accomodationCertificate.maxLength,
                        errors.files.accomodationCertificate.required,
                    ),
                    indentityDoc: fileValidation(
                        errors.files.indentityDoc.maxLength,
                        errors.files.indentityDoc.required,
                    ),
                }),
            })
            .when('socioprofessionalCategory', {
                is: (val: SocioprofessionalCategories) => blueCategories.includes(val),
                then: Yup.object().shape({
                    ...baseFilesValidation,
                    payslips: fileValidation(errors.files.payslips.maxLength, errors.files.payslips.required),
                }),
            }),
    });
};

export const requestContractTransferValidationSchema = (
    field: string,
    errors: RequestContractTransferValidatorErrors = RequestContractTransferError,
): Yup.SchemaOf<RequestContractTransferFrontendFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory) => {
            return requestCategory === 'REQUEST_CONTRACT_TRANSFER';
        },
        then: requestContractTransferFrontendValidationSchema(errors),
    });
};
