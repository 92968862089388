import * as Yup from 'yup';
import { RequestAuthorizationLeaveCountryValidatorErrors } from '../../types';
import { RequestAuthorizationLeaveCountryError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestAuthorizationLeaveCountryFormType = {
    email: string;
    phonenumber: string | undefined;
    exitDate: string;
    returnDate: string;
    reason: string;
    countryOfDestination: string;
};

export const requestAuthorizationLeaveCountryCoreSchema = (
    errors: RequestAuthorizationLeaveCountryValidatorErrors = RequestAuthorizationLeaveCountryError,
): Yup.SchemaOf<RequestAuthorizationLeaveCountryFormType> =>
    Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        exitDate: Yup.string().required(errors.exitDate.required),
        returnDate: Yup.string().required(errors.returnDate.required),
        reason: Yup.string().required(errors.reason.required),
        countryOfDestination: Yup.string().required(errors.countryOfDestination.required),
    });

export const requestAuthorizationLeaveCountryValidationSchema = (
    field: string,
    errors: RequestAuthorizationLeaveCountryValidatorErrors = RequestAuthorizationLeaveCountryError,
): Yup.SchemaOf<RequestAuthorizationLeaveCountryFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'AUTHORIZATION_LEAVE_COUNTRY';
        },
        then: requestAuthorizationLeaveCountryCoreSchema(errors),
    });
};
