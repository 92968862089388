import { Base64File } from '@cp-fr/common';
import { groupByFileSize, mapFilesToBase64 } from 'utils/file-utils';
import { RequestInitialValues } from '../../../../initialValues';
import { maxFileSize } from '../../../../../../../config';
import { TenantChangeRequest } from '@cp-fr/common/src/apis/services/types/request';

export const tenantChangeRequestMapper = async ({
    contractsSelection,
    tenantChangeRequest,
}: RequestInitialValues): Promise<TenantChangeRequest[]> => {
    const {
        dialogueSheet,
        idCopy,
        taxPackage,
        kbisOrInsee,
        newTenantIban,
        vehicleId,
        idCopyManager,
        taxReturn,
    } = tenantChangeRequest.files;
    const files = [
        ...dialogueSheet,
        ...idCopy,
        ...taxPackage,
        ...kbisOrInsee,
        ...newTenantIban,
        ...vehicleId,
        ...idCopyManager,
        ...taxReturn,
    ];

    const filesForRequest = await mapFilesToBase64(files);

    const mapToRequest = (files: Base64File[]): TenantChangeRequest => ({
        requestCategory: 'TENANT',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: tenantChangeRequest.email,
        phonenumber: tenantChangeRequest.phonenumber,
        transferDate: tenantChangeRequest.transferDate,
        reason: tenantChangeRequest.reason,
        transferTo: tenantChangeRequest.transferTo,
        files,
        comment: tenantChangeRequest.comment,
    });

    return groupByFileSize(filesForRequest, maxFileSize).map((group) => mapToRequest(group));
};
