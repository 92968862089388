import { dashboardPagePath } from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';

export const EarlyReturnPenaltyModal: React.FC<{
    status?: 'success' | 'error' | 'inProgress';
    onModalClose: () => void;
}> = ({ status, onModalClose }) => {
    const { t } = useTranslation('early-return-penalty');
    const history = useHistory();

    if (!status) return null;

    return (
        <Modal
            shown={status === 'success' || status === 'error'}
            status={status}
            onClose={status === 'success' ? (): void => history.push(dashboardPagePath()) : onModalClose}
            onCancel={(): void => history.push(dashboardPagePath())}
            buttonCancelText={t('modal.button')}
            title={status === 'success' ? t(`modal.${status}.title`) : ''}
            testId={`${status}-modal`}
        >
            {t(`modal.${status}.text`)}
        </Modal>
    );
};
