import { NotificationStatus, Notification } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../edit-view/EditView';

type NameChangeNotificationProps = {
    lastEditStatus: EditStatus;
};

type NotificationProps = {
    testId: string;
    status: NotificationStatus;
    headline?: string;
    text: string | React.ReactNode;
};

export const NameChangeNotification: React.FC<NameChangeNotificationProps> = ({ lastEditStatus }) => {
    const { t } = useTranslation('my-profile');

    if (lastEditStatus === EditStatus.NOT_PERFORMED) {
        return null;
    }

    const getNotificationProps = (): NotificationProps => {
        switch (lastEditStatus) {
            case EditStatus.ERROR_VIRUS:
                return {
                    testId: 'virus-error',
                    status: NotificationStatus.error,
                    headline: t('identification.notification.virus.title'),
                    text: t('identification.notification.virus.text'),
                };
            case EditStatus.ERROR:
                return {
                    testId: 'no-connection-error',
                    status: NotificationStatus.error,
                    text: t('identification.notification.error'),
                };
            case EditStatus.NO_CHANGE_DETECTED:
                return {
                    testId: 'no-change-error',
                    status: NotificationStatus.error,
                    text: t('identification.notification.no-change'),
                };
            case EditStatus.WRONG_REASON:
                return {
                    testId: 'wrong-reason-error',
                    status: NotificationStatus.error,
                    text: t('identification.notification.wrong-reason'),
                };
            case EditStatus.SUCCESS:
                return {
                    testId: 'success',
                    status: NotificationStatus.success,
                    headline: t('identification.notification.success.title'),
                    text: t('identification.notification.success.text'),
                };
        }
    };

    return <Notification {...getNotificationProps()} className={'u-mb'} />;
};
