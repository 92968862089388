import * as Yup from 'yup';
import { RequestLossValidatorErrors } from '../../types';
import { RequestLossError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestLossFormType = {
    email: string;
    phonenumber: string | undefined;
    natureOfClaim: string;
    startDate: string;
};

export const requestLossCoreSchema = (
    errors: RequestLossValidatorErrors = RequestLossError,
): Yup.SchemaOf<RequestLossFormType> =>
    Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        natureOfClaim: Yup.string().trim().required(errors.natureOfClaim.required),
        startDate: Yup.string().required(errors.startDate.required),
    });

export const requestLossValidationSchema = (
    field: string,
    errors: RequestLossValidatorErrors = RequestLossError,
): Yup.SchemaOf<RequestLossFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'LOSS';
        },
        then: requestLossCoreSchema(errors),
    });
};
