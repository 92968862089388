import { Base64File } from '@cp-fr/common';
import { RegistrationCertificateRequest } from '@cp-fr/common/src/apis/services/types/request';
import { groupByFileSize, mapFilesToBase64 } from 'utils/file-utils';
import { maxFileSize } from '../../../../../../../config';
import { RequestInitialValues } from '../../../../initialValues';

export const registrationCertificateDuplicateMapper = async ({
    contractsSelection,
    registrationCertificateDuplicate,
}: RequestInitialValues): Promise<RegistrationCertificateRequest[]> => {
    const {
        id,
        registrationCertificate,
        lossDeclaration,
        theftDeclaration,
        proofOfAddress,
    } = registrationCertificateDuplicate.files;

    const files = [...id, ...registrationCertificate, ...proofOfAddress];

    registrationCertificateDuplicate.requestReason === 'Vol'
        ? files.push(...(theftDeclaration || []))
        : files.push(...(lossDeclaration || []));

    const filesForRequest = await mapFilesToBase64(files);

    const mapToRequest = (files: Base64File[]): RegistrationCertificateRequest => ({
        requestCategory: 'REGISTRATION_CERTIFICATE_DUPLICATE',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: registrationCertificateDuplicate.email,
        phonenumber: registrationCertificateDuplicate.phonenumber,
        postalAddress: registrationCertificateDuplicate.postalAddress,
        requestReason: registrationCertificateDuplicate.requestReason,
        files,
    });

    return groupByFileSize(filesForRequest, maxFileSize).map((group) => mapToRequest(group));
};
