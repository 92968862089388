import { useMemo, useState } from 'react';
import {
    RegistrationDataPhysical,
    RegistrationDataLegal,
    RegistrationError,
    getRegistrationLegalEndpoint,
    getRegistrationPhysicalEndpoint,
} from '@cp-fr/common';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';
import { CpDataApi } from 'cp-xhr';

type RegistrationSubmissionProps = {
    submit: (values: RegistrationDataPhysical | RegistrationDataLegal) => void;
};

type RegistrationRequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError>;
};

const mapBodyRegistrationPhysical = (values: RegistrationDataPhysical) => {
    const { contractNumber, firstName, surname, dateOfBirth, siren } = values;
    const body = {
        contractNumber: contractNumber.trim(),
        firstName: firstName.trim(),
        surname: surname.trim(),
        siren: siren ? siren.trim() : undefined,
        dateOfBirth,
    };
    return body;
};

const mapBodyRegistrationLegal = (values: RegistrationDataLegal) => {
    const { contractNumber, customerNumber, companyName, siren } = values;
    const body = {
        contractNumber: contractNumber.trim(),
        customerNumber: customerNumber.trim(),
        companyName: companyName.trim(),
        siren: siren.trim(),
    };
    return body;
};

export const useRegistrationSubmission = (
    onSuccess: () => void,
    onError: () => void,
    isLegal: boolean,
): RegistrationSubmissionProps & RegistrationRequestProps => {
    const [registrationRequest, setRegistrationRequest] = useState<RegistrationRequestProps>({
        isLoading: false,
        errorCode: undefined,
    });
    const submit = useMemo(
        () => (values: RegistrationDataPhysical | RegistrationDataLegal): void => {
            setRegistrationRequest({ errorCode: undefined, isLoading: true });

            const endpoint = isLegal ? getRegistrationLegalEndpoint() : getRegistrationPhysicalEndpoint();
            const body = isLegal
                ? mapBodyRegistrationLegal(values as RegistrationDataLegal)
                : mapBodyRegistrationPhysical(values as RegistrationDataPhysical);

            CpDataApi.post(endpoint, body)
                .then(() => {
                    setRegistrationRequest({
                        isLoading: false,
                    });
                    onSuccess();
                })
                .catch((error) => {
                    const errorCode = parseErrorResponse<RegistrationError>(error).code;
                    setRegistrationRequest({
                        isLoading: false,
                        errorCode,
                    });
                    onError();
                });
        },
        [onSuccess, onError, isLegal],
    );
    return { submit, ...registrationRequest };
};
