import React, { useState } from 'react';
import { Button, ButtonContainer, Card, Layout, Modal, TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import {
    Contract,
    ContractEndOptionsDetails,
    ContractEndOptionsEN,
    getEndOptionEndpoint,
    MyProfileData,
} from '@cp-fr/common';
import { ContractHeader, Notification, NotificationStatus, Spinner } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { LicensePlate } from 'components/license-plate/LicensePlate';
import { dashboardPagePath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from 'cp-xhr';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { useUpdateContractsAll } from '../../useUpdateContractsAll';

type ContractEndOptionsRequestProps = {
    isError: boolean;
    isSuccess: boolean;
    optionValue: ContractEndOptionsEN | undefined;
};

enum OptionsView {
    MAIN = 'MAIN',
    BUY_A_CAR = 'BUY_A_CAR',
}

export const ContractEndOptionsUi: React.FC<{ contract?: Contract; myProfile?: MyProfileData }> = ({
    contract,
    myProfile,
}) => {
    const { t } = useTranslation('contract-end-options');
    const history = useHistory();
    const [currentOptions, setCurrentOptions] = useState<OptionsView>(OptionsView.MAIN);
    const [contractEndOptionsRequest, setContractEndOptionsRequest] = useState<ContractEndOptionsRequestProps>({
        isError: false,
        isSuccess: false,
        optionValue: undefined,
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { updateContracts, contractsData } = useUpdateContractsAll();

    if (!contract) {
        return <Notification status={NotificationStatus.error} text={t('error.text')} />;
    }

    const { contractNumber, productType = '', carModel = ' ', licensePlate = '', contractId } = contract;

    const { brand: contractBrand } = contract;
    const brand = contractBrand ? contractBrand.toLowerCase() : null;
    const isRetail = myProfile?.identification?.customerType === 'Retail';

    let vehicleRestitutionGuideBrand: null | string = null;
    switch (brand) {
        case 'vwfs':
            vehicleRestitutionGuideBrand = 'vw';
            break;
        case 'vwcv':
            vehicleRestitutionGuideBrand = null;
            break;
        default:
            vehicleRestitutionGuideBrand = brand;
            break;
    }

    const updateIsEligibleRenewalFlag = (): void => {
        const updatedContractsData = [...contractsData].map((contract) => {
            if (contract.contractId === contractId) {
                return { ...contract, isEligibleRenewalContractNotification: false };
            }
            return contract;
        });
        updateContracts(updatedContractsData);
    };

    const handleModalClose = () => {
        setContractEndOptionsRequest({
            isError: false,
            isSuccess: false,
            optionValue: undefined,
        });
    };

    const redirectToDashboard = () => {
        history.push(dashboardPagePath());
    };

    const handleChange = (option: ContractEndOptionsEN) => {
        setContractEndOptionsRequest({
            ...contractEndOptionsRequest,
            optionValue: option,
        });
    };

    const clearChosenOption = () => {
        setContractEndOptionsRequest({
            ...contractEndOptionsRequest,
            optionValue: undefined,
        });
    };

    const handleSubmit = () => {
        if (contractEndOptionsRequest.optionValue === undefined) {
            return;
        }

        const preparedData: ContractEndOptionsDetails = {
            email: myProfile?.contactDetails?.email ? myProfile?.contactDetails?.email : '',
            endOption: contractEndOptionsRequest.optionValue,
        };
        setIsSubmitting(true);
        CpDataApi.put(getEndOptionEndpoint(contract.contractId), preparedData)
            .then(() => {
                setContractEndOptionsRequest({ ...contractEndOptionsRequest, isSuccess: true });
                updateIsEligibleRenewalFlag();
            })
            .catch(() => {
                setContractEndOptionsRequest({ ...contractEndOptionsRequest, isError: true });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const refinancingOption = formatCpDate(contract.returnBackVehicleDate)
        .toMoment()
        .subtract(1, 'months')
        .isBefore(new Date()) ? (
        <TileSelect
            icon="semantic-calculator"
            title={t('options.refinancing.headline')}
            inputType="radio"
            radioGroupName="buyACarOptions"
            onClick={() => handleChange(ContractEndOptionsEN.REFINANCING)}
            testId={'tile-select-refinancing'}
        >
            {t('options.refinancing.description')}
        </TileSelect>
    ) : undefined;

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Layout>
                <Layout.Item>
                    <Card element="article" title="">
                        <ContractHeader
                            contractIdentifier={<LicensePlate registrationNumber={licensePlate} />}
                            carInformation={carModel}
                            contractCategory={productType}
                            contractNumber={contractNumber}
                        />
                    </Card>
                </Layout.Item>
                <Layout.Item>
                    {currentOptions === OptionsView.MAIN ? (
                        <TileSelectGroup
                            key={OptionsView.MAIN}
                            layoutItemClassName="u-1/3 u-1/2@l u-1/1@s"
                            className={'u-text-center'}
                            testId={'tile-select-group-main'}
                        >
                            <TileSelect
                                icon="semantic-car"
                                title={t('options.renewal.headline')}
                                inputType="radio"
                                radioGroupName="contractEndMainOptions"
                                onClick={() => handleChange(ContractEndOptionsEN.RENEWAL)}
                            >
                                {t('options.renewal.description')}
                                {isRetail && vehicleRestitutionGuideBrand && (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('options.renewal.vehicle-restitution-guide', {
                                                brand: vehicleRestitutionGuideBrand,
                                            }),
                                        }}
                                    />
                                )}
                            </TileSelect>
                            <TileSelect
                                icon="semantic-accessoires"
                                title={t('options.buy-a-car.headline')}
                                inputType="radio"
                                radioGroupName="contractEndMainOptions"
                                testId={'tile-select-buy-a-car'}
                                onClick={() => {
                                    clearChosenOption();
                                    setCurrentOptions(OptionsView.BUY_A_CAR);
                                }}
                            >
                                {t('options.buy-a-car.description')}
                            </TileSelect>
                            <TileSelect
                                icon="semantic-reload"
                                title={t('options.return.headline')}
                                inputType="radio"
                                radioGroupName="contractEndMainOptions"
                                onClick={() => handleChange(ContractEndOptionsEN.RETURN)}
                            >
                                {isRetail && vehicleRestitutionGuideBrand ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('options.return.vehicle-restitution-guide', {
                                                brand: vehicleRestitutionGuideBrand,
                                            }),
                                        }}
                                    />
                                ) : (
                                    t('options.return.description')
                                )}
                            </TileSelect>
                        </TileSelectGroup>
                    ) : (
                        <TileSelectGroup
                            key={OptionsView.BUY_A_CAR}
                            layoutItemClassName={`u-1/${refinancingOption ? 2 : 1} u-1/2@l u-1/1@s`}
                            className={'u-text-center'}
                            testId={'tile-select-group-buy'}
                        >
                            {refinancingOption}
                            <TileSelect
                                icon="semantic-fs-payment"
                                title={t('options.payment.headline')}
                                inputType="radio"
                                radioGroupName="buyACarOptions"
                                onClick={() => handleChange(ContractEndOptionsEN.PAYMENT)}
                            >
                                {t('options.payment.description')}
                            </TileSelect>
                        </TileSelectGroup>
                    )}
                </Layout.Item>
                <Layout.Item>
                    <ButtonContainer center>
                        <Button
                            secondary
                            testId="backButton"
                            onClick={() => {
                                if (currentOptions === OptionsView.MAIN) redirectToDashboard();
                                else if (currentOptions === OptionsView.BUY_A_CAR) {
                                    clearChosenOption();
                                    setCurrentOptions(OptionsView.MAIN);
                                }
                            }}
                        >
                            {t('buttons.back')}
                        </Button>
                        <Button
                            testId="submitButton"
                            disabled={!contractEndOptionsRequest.optionValue}
                            onClick={handleSubmit}
                        >
                            {t('buttons.submit')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <Modal
                shown={contractEndOptionsRequest.isSuccess}
                status="success"
                onClose={redirectToDashboard}
                onCancel={redirectToDashboard}
                buttonCancelText={t('success.button')}
                testId={'success-modal'}
                title={t('success.headline')}
            >
                {t('success.text')}
            </Modal>
            <Modal
                shown={contractEndOptionsRequest.isError}
                status="error"
                onClose={handleModalClose}
                onCancel={handleModalClose}
                testId={'error-modal'}
            >
                {t('error.text')}
            </Modal>
        </>
    );
};
