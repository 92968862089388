import * as Yup from 'yup';
import { RequestRegistrationCertificateDuplicateValidatorErrors } from '../../types';
import { RequestRegistrationCertificateDuplicateError } from '../../messages';
import { RequestCategory, RegistrationCertificateDuplicateReason } from '../../../apis/services/types/request';
import { fileValidation } from '../FileValidator';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestRegistrationCertificateDuplicateFormType = {
    email: string;
    phonenumber: string | undefined;
    requestReason: string;
};

export type RequestRegistrationCertificateDuplicateBackendFormType = RequestRegistrationCertificateDuplicateFormType & {
    files: {
        base64: string | undefined;
        filename: string | undefined;
        mimeType: string | undefined;
        size: number | undefined;
    }[];
};

export type RequestRegistrationCertificateDuplicateFrontendFormType = RequestRegistrationCertificateDuplicateFormType & {
    files: {
        id: string;
        proofOfAddress: string;
        registrationCertificate: string;
        lossDeclaration?: string;
        theftDeclaration?: string;
    };
};

export const requestRegistrationCertificateDuplicateCoreSchema = (
    errors: RequestRegistrationCertificateDuplicateValidatorErrors = RequestRegistrationCertificateDuplicateError,
): Yup.SchemaOf<RequestRegistrationCertificateDuplicateBackendFormType> => {
    return Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        requestReason: Yup.string().trim().required(errors.requestReason.required),
        files: Yup.array()
            .of(
                Yup.object().shape({
                    base64: Yup.string(),
                    filename: Yup.string(),
                    mimeType: Yup.string(),
                    size: Yup.number(),
                }),
            )
            .min(2, errors.files.id.required),
    });
};

export const requestRegistrationCertificateDuplicateFrontendFilesSchema = (
    errors: RequestRegistrationCertificateDuplicateValidatorErrors = RequestRegistrationCertificateDuplicateError,
): Yup.SchemaOf<RequestRegistrationCertificateDuplicateFrontendFormType> => {
    return requestRegistrationCertificateDuplicateCoreSchema(errors)
        .omit(['files'])
        .shape({
            files: Yup.object()
                .when('requestReason', {
                    is: (val: RegistrationCertificateDuplicateReason) => val === 'Perte',
                    then: Yup.object().shape({
                        id: fileValidation(errors.files.id.maxLength, errors.files.id.required),
                        proofOfAddress: fileValidation(
                            errors.files.proofOfAddress.maxLength,
                            errors.files.proofOfAddress.required,
                        ),
                        registrationCertificate: fileValidation(errors.files.registrationCertificate.maxLength),
                        lossDeclaration: fileValidation(errors.files.lossDeclaration.maxLength),
                    }),
                })
                .when('requestReason', {
                    is: (val: RegistrationCertificateDuplicateReason) => val === 'Vol',
                    then: Yup.object().shape({
                        id: fileValidation(errors.files.id.maxLength, errors.files.id.required),
                        proofOfAddress: fileValidation(
                            errors.files.proofOfAddress.maxLength,
                            errors.files.proofOfAddress.required,
                        ),
                        registrationCertificate: fileValidation(errors.files.registrationCertificate.maxLength),
                        theftDeclaration: fileValidation(errors.files.theftDeclaration.maxLength),
                    }),
                }),
        });
};

export const requestRegistrationCertificateDuplicateValidationSchema = (
    field: string,
    errors: RequestRegistrationCertificateDuplicateValidatorErrors = RequestRegistrationCertificateDuplicateError,
): Yup.SchemaOf<RequestRegistrationCertificateDuplicateFrontendFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'REGISTRATION_CERTIFICATE_DUPLICATE';
        },
        then: requestRegistrationCertificateDuplicateFrontendFilesSchema(errors),
    });
};
