import React from 'react';
import { Button } from '@vwfs-bronson/bronson-react';
import { openInNewWindow, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { MarketingCard } from '@cp-fr/common';

type MarketingCardButtonProps = {
    cardType: 'MAINTENANCE_SUBSCRIPTION' | 'MOTOR_INSURANCE';
    brand?: string;
    isMultipleMatch?: boolean;
    onlineServicePlanUrl?: string;
    content?: MarketingCard;
};

export const MarketingCardButton: React.FC<MarketingCardButtonProps> = ({
    brand,
    isMultipleMatch,
    cardType,
    onlineServicePlanUrl,
    content,
}) => {
    const { onAction } = useAnalyticsActionTracker('onClickMarketingCard');

    if (!content) return null;

    const { maintenanceSubscription, motorInsurance } = content;

    const getLinkBrand = () => {
        switch (brand?.toLocaleLowerCase()) {
            case 'audi':
                return 'audi';
            case 'seat':
                return 'seat';
            case 'skoda':
                return 'skoda';
            default:
                return 'vw';
        }
    };

    const linkBrand = getLinkBrand();

    const handleClick = () => {
        const link =
            cardType === 'MAINTENANCE_SUBSCRIPTION'
                ? `${onlineServicePlanUrl}${maintenanceSubscription.traciLinks[linkBrand]}`
                : motorInsurance.links[linkBrand];
        const title = cardType === 'MAINTENANCE_SUBSCRIPTION' ? maintenanceSubscription.title : motorInsurance.title;
        onAction(title);
        openInNewWindow(link)();
    };

    const getButtonText = () => {
        if (cardType === 'MAINTENANCE_SUBSCRIPTION') return maintenanceSubscription.buttonText;
        return isMultipleMatch
            ? `${motorInsurance.buttonText.multipleMatches} ${linkBrand}`
            : motorInsurance.buttonText.oneMatch;
    };

    return (
        <Button link={true} icon="semantic-forward" iconReversed small onClick={handleClick}>
            {getButtonText()}
        </Button>
    );
};
