import { CleaveInput, preventSubmit, ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import {
    Button,
    ButtonContainer,
    Fieldset,
    Form,
    Layout,
    RadioButton,
    RadioButtonGroup,
} from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from 'components/navigation/paths';
import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { partialEarlySettlementValidationSchema } from '@cp-fr/common';
import { monthNames } from '../../../../../utils/monthNames';
import { getInitialValues } from './initialValues';

export type FormData = {
    requestDate: string;
    amount: string;
    goal: string;
    reason: string;
};

type PartialSettlementFormProps = {
    handleSubmit: (data: FormData) => void;
};

const availabelGoals = [
    {
        label: 'Réduire le montant des échéances mensuelles',
        value: 'MONTHLY_AMOUNT_REDUCTION',
    },
    {
        label: 'Réduire la durée totale de votre financement',
        value: 'INSTALLMENT_NUMBER_REDUCTION',
    },
];

const availableReasons = [
    {
        label: 'Revente du véhicule',
        value: 'VEHICLE_RESALE',
    },
    {
        label: 'Rachat de crédit',
        value: 'CREDIT_REPURCHASE',
    },
    {
        label: 'Epargne personnelle',
        value: 'PERSONAL_SAVINGS',
    },
    {
        label: 'Prime',
        value: 'BONUS',
    },
    {
        label: 'Héritage',
        value: 'HERITAGE',
    },
];

const availableMonths: ValidatedSelectItem[] = [];

monthNames.forEach((month, index) =>
    availableMonths.push({
        label: month,
        value: moment()
            .set({ month: index, date: moment().month(index).daysInMonth() })
            .format('YYYY-MM-DD'),
    }),
);

const translationPrefix = 'partial-early-settlement.form';

export const PartialEarlySettlementForm: React.FC<PartialSettlementFormProps> = ({ handleSubmit }) => {
    const { t } = useTranslation('early-settlement');
    const history = useHistory();
    const translationFormErrorsPrefix = 'partial-early-settlement.form.errors';

    const errorMessages = {
        requestDate: {
            required: t(`${translationFormErrorsPrefix}.empty-date`),
        },
        reason: {
            required: t(`${translationFormErrorsPrefix}.empty-reason`),
        },
        amount: {
            required: t(`${translationFormErrorsPrefix}.empty-amount`),
        },
        goal: {
            required: '',
        },
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            onSubmit={(data: FormData): void => {
                handleSubmit(data);
            }}
            validationSchema={partialEarlySettlementValidationSchema(errorMessages)}
        >
            {(formik): JSX.Element => (
                <Form onSubmit={(e) => preventSubmit(e)} data-testid="partial-settlement-form">
                    <Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item default={'1/2'} m={'1/1'}>
                                    <ValidatedSelect
                                        name={'requestDate'}
                                        label={t(`${translationPrefix}.request-date`)}
                                        placeholder={t(`${translationPrefix}.request-date-placeholder`)}
                                        selectItems={availableMonths.map(({ value, label }) => ({
                                            value,
                                            label,
                                        }))}
                                    />
                                </Layout.Item>
                                <Layout.Item default={'1/2'} m={'1/1'}>
                                    <CleaveInput
                                        cleaveOptions={{
                                            numeral: true,
                                            delimiter: '',
                                            numeralDecimalMark: ',',
                                        }}
                                        name="amount"
                                        addonText="€"
                                        label={t(`${translationPrefix}.amount`)}
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <p>{t(`${translationPrefix}.goal`)}</p>
                            <RadioButtonGroup>
                                {availabelGoals.map(({ value, label }, index) => (
                                    <RadioButton
                                        key={index}
                                        testId={`goal-${index}`}
                                        name="goal"
                                        value={value}
                                        defaultChecked={index === 0}
                                        onChange={(): void => formik.setFieldValue('goal', value)}
                                    >
                                        {label}
                                    </RadioButton>
                                ))}
                            </RadioButtonGroup>
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <ValidatedSelect
                                isMandatory={false}
                                name={'reason'}
                                label={t(`${translationPrefix}.reason`)}
                                placeholder={t(`${translationPrefix}.reason-placeholder`)}
                                selectItems={availableReasons.map(({ value, label }) => ({
                                    value,
                                    label,
                                }))}
                            />
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <ButtonContainer center>
                                <Button
                                    secondary
                                    onClick={(): void => history.push(dashboardPagePath())}
                                    testId="cancelButton"
                                >
                                    {t('translation:editable-section-nav.cancel')}
                                </Button>
                                <Button onClick={formik.handleSubmit} type="submit" testId="send">
                                    {t('translation:editable-section-nav.send')}
                                </Button>
                            </ButtonContainer>
                        </Fieldset.Row>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
