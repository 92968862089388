import { Contract, ContractDetails as TContractDetails, ProductTypeEN } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { InfoIcon, useAnalyticsDashboardPageTrackerWithoutUnpaid } from '@cp-shared-8/frontend-ui';
import { Button, ExpandableContent, FormSectionGroup, Notification } from '@vwfs-bronson/bronson-react';
import { contractEndOptionsPath } from 'components/navigation/paths';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { ContractComponent } from '../contract';

export const ContractsOverview: React.FC<{
    contracts: Array<Contract & TContractDetails>;
    isContactInformationChangePending: boolean;
}> = ({ contracts, isContactInformationChangePending }) => {
    const { t } = useTranslation('contracts');
    const { t: mt } = useTranslation('my-profile');
    const history = useHistory();

    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? '/authentication/callback' : lastLocation?.pathname;

    const getVehicleRestitutionGuideBrand = (brand?: string) => {
        if (!brand) return null;

        if (brand === 'vwfs') return 'vw';
        if (brand === 'vwcv') return null;
        return brand.toLowerCase();
    };

    useAnalyticsDashboardPageTrackerWithoutUnpaid(!!contracts.length, lastPathname);

    const getListOfContracts = useCallback(
        (isActive: boolean): React.ReactNode | null => {
            const filteredContracts = contracts ? contracts.filter((contract) => contract.isActive === isActive) : [];
            return filteredContracts.length ? (
                <FormSectionGroup>
                    {filteredContracts.map((contract, index) => {
                        const defaultExpanded = isActive && index === 0;
                        return (
                            <ContractComponent
                                contract={contract}
                                key={contract.contractId}
                                defaultExpanded={defaultExpanded}
                                isContactInformationChangePending={isContactInformationChangePending}
                            />
                        );
                    })}
                </FormSectionGroup>
            ) : null;
        },
        [contracts, isContactInformationChangePending],
    );

    const handleContractEndOptionsClicked = (contract: Contract) => {
        history.push(contractEndOptionsPath(contract.contractId));
    };

    const endsInLessThan5Months = (contractEndDate: string): boolean => {
        return formatCpDate(contractEndDate).toMoment().diff(moment(new Date()), 'months') <= 5;
    };

    const endsInLessThan6Months = (contractEndDate: string): boolean => {
        return formatCpDate(contractEndDate).toMoment().diff(moment(new Date()), 'months') <= 6;
    };

    const endingContractsInLessThan5Months = contracts
        ? contracts.filter((contract) => contract.isActive && endsInLessThan5Months(contract.contractEndDate))
        : [];

    const endingContractsInLessThan6Months = contracts
        ? contracts.filter((contract) => contract.isActive && endsInLessThan6Months(contract.contractEndDate))
        : [];

    const contractEndInfoInLessThan5Months = endingContractsInLessThan5Months.map((contract, index) => {
        return (
            <React.Fragment key={`contractEndInfoInLessThan5Months-${index}`}>
                {contract.productTypeEN === ProductTypeEN.CREDIT && (
                    <Notification
                        visible
                        title={t('contract-end-info.headline')}
                        showCloseButton={false}
                        status="info"
                        testId="credit-infobox"
                    >
                        {t('contract-end-info.description.credit', { value: contract.contractNumber })}
                    </Notification>
                )}
                {contract.productTypeEN === ProductTypeEN.BALLON_LEASING &&
                    contract.isEligibleRenewalContractNotification && (
                        <Notification
                            visible
                            title={t('contract-end-info.headline')}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                            text={t('contract-end-info.description.ballon', { value: contract.contractNumber })}
                        >
                            <div>
                                <Button
                                    onClick={() => handleContractEndOptionsClicked(contract)}
                                    className="u-mt"
                                    disabled={isContactInformationChangePending}
                                >
                                    {t('contract-end-info.button')}
                                </Button>
                                {isContactInformationChangePending && (
                                    <InfoIcon text={mt('notifications.contact-information-change-pending')} />
                                )}
                            </div>
                        </Notification>
                    )}
            </React.Fragment>
        );
    });

    const contractEndInfoInLessThan6Months = endingContractsInLessThan6Months.map((contract, index) => {
        const vehicleRestitutionGuideBrand = getVehicleRestitutionGuideBrand(contract.brand);

        if (vehicleRestitutionGuideBrand) {
            return (
                <React.Fragment key={`contractEndInfoInLessThan6Months-${index}`}>
                    {contract.productTypeEN === ProductTypeEN.OPERATIVE_LEASING && (
                        <Notification
                            visible
                            title={t('contract-end-info.headline')}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            <div>
                                {vehicleRestitutionGuideBrand && (
                                    <>
                                        {t('contract-end-info.description.loun', {
                                            contractNumber: contract.contractNumber,
                                        })}
                                        {contract.productType !== 'VDL' && (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t('contract-end-info.description.loun-guide', {
                                                        brand: vehicleRestitutionGuideBrand,
                                                    }),
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                                {isContactInformationChangePending && (
                                    <InfoIcon text={mt('notifications.contact-information-change-pending')} />
                                )}
                            </div>
                        </Notification>
                    )}
                </React.Fragment>
            );
        }

        return <React.Fragment key={`contractEndInfoInLessThan6Months-${index}`} />;
    });

    const renderContractEndInfo = () => {
        if (contractEndInfoInLessThan5Months || contractEndInfoInLessThan6Months) {
            return (
                <div className={'u-mb'}>
                    {contractEndInfoInLessThan5Months}
                    {contractEndInfoInLessThan6Months}
                </div>
            );
        }
        return null;
    };

    const memoizedListOfActiveContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);
    const memoizedListOfInactiveContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);
    return (
        <>
            {renderContractEndInfo()}
            {memoizedListOfActiveContracts}
            {!!memoizedListOfInactiveContracts && (
                <div className={'u-mt-large'}>
                    <h4 className="u-text-center">{t('expand.headline')}</h4>
                    <ExpandableContent
                        lazyRender
                        className={'u-mt'}
                        noBackground
                        pageWrapClassName={'u-pl-none u-pr-none'}
                        triggerLabel={t('expand.trigger')}
                    >
                        {memoizedListOfInactiveContracts}
                    </ExpandableContent>
                </div>
            )}
        </>
    );
};
