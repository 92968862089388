import * as Yup from 'yup';
import { RequestAuthorizationDebitValidatorErrors } from '../../types';
import { RequestAuthorizationDebitError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestAuthorizationDebitFormType = {
    email: string;
    phonenumber: string | undefined;
    nameSurname: string;
    authorizes: string;
    amount: string;
    balanceReason: string;
};

export const requestAuthorizationDebitCoreSchema = (
    errors: RequestAuthorizationDebitValidatorErrors = RequestAuthorizationDebitError,
): Yup.SchemaOf<RequestAuthorizationDebitFormType> =>
    Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        nameSurname: Yup.string().trim().required(errors.nameSurname.required),
        authorizes: Yup.string().trim().required(errors.authorizes.required),
        amount: Yup.string().trim().required(errors.amount.required),
        balanceReason: Yup.string().trim().required(errors.balanceReason.required),
    });

export const requestAuthorizationDebitValidationSchema = (
    field: string,
    errors: RequestAuthorizationDebitValidatorErrors = RequestAuthorizationDebitError,
): Yup.SchemaOf<RequestAuthorizationDebitFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'AUTHORIZATION_DEBIT';
        },
        then: requestAuthorizationDebitCoreSchema(errors),
    });
};
