import { Contract, ContractDetails as TContractDetails, hasUnpaidAmmount } from '@cp-fr/common';
import { Card } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { LicensePlate } from '../../license-plate/LicensePlate';
import { ContractDetails } from '../contract-details';
import { UnpaidAlertNotification } from '../unpaid-alert-notifications/UnpaidAlertNotification';

export const ContractComponent: React.FC<{
    contract: Contract & TContractDetails;
    defaultExpanded: boolean;
    isContactInformationChangePending: boolean;
}> = ({ contract, defaultExpanded, isContactInformationChangePending }) => {
    const { contractNumber, productType = '', carModel = ' ', licensePlate = '', amountUnpaidNotPaid } = contract;

    return (
        <Card
            element="article"
            expandable
            title=""
            defaultExpanded={defaultExpanded}
            className={'u-mb'}
            contentLarge={
                <ContractDetails
                    contract={contract}
                    isContactInformationChangePending={isContactInformationChangePending}
                />
            }
        >
            <>
                <div className="c-card__header-items">
                    <h4 className="c-card__title">{carModel}</h4>
                    <LicensePlate registrationNumber={licensePlate} />
                </div>
                <p className="c-card__subtitle">
                    {`Type de financement: ${productType}`} <br /> {`N° dossier: ${contractNumber}`}
                </p>
                {hasUnpaidAmmount(contract) && <UnpaidAlertNotification amountUnpaidNotPaid={amountUnpaidNotPaid} />}
            </>
        </Card>
    );
};
