import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { uniq } from 'lodash';
import { Card } from '@vwfs-bronson/bronson-react';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { BundledProductWithMarketingInfo, MarketingCard, FR_TIMEZONE, Contract, MyProfileData } from '@cp-fr/common';
import { MarketingCardButton } from '../MarketingCardButton';
import { currentBrand } from '../../../config';
import {
    isExtendedMaintenanceSubscriptionDeadline,
    MONTHS_FOR_MAINTENANCE_SUBSCRIPTION,
    YEARS_FOR_AUDI_AND_SEAT_MAINTENANCE_SUBSCRIPTION,
} from '../utils';

type MarketingCardUiProps = {
    productsWithMaintenance?: BundledProductWithMarketingInfo[];
    otherProducts?: BundledProductWithMarketingInfo[];
    content?: MarketingCard;
    onlineServicePlanUrl?: string;
    contracts?: Contract[];
    setShouldRender: (shouldRender: boolean) => void;
    profileData?: MyProfileData;
};

export const MarketingCardUi: React.FC<MarketingCardUiProps> = ({
    productsWithMaintenance = [],
    otherProducts = [],
    content,
    onlineServicePlanUrl,
    contracts,
    setShouldRender,
    profileData,
}) => {
    const isMaintenanceSubscriptionAvailable = (): boolean => {
        const currentDate = moment().tz(FR_TIMEZONE);

        if (productsWithMaintenance.length > 0) return false;
        if (!onlineServicePlanUrl) return false;

        const maintenanceSubscriptionDeadlineArguments = isExtendedMaintenanceSubscriptionDeadline(contracts)
            ? [YEARS_FOR_AUDI_AND_SEAT_MAINTENANCE_SUBSCRIPTION, 'years']
            : [MONTHS_FOR_MAINTENANCE_SUBSCRIPTION, 'months'];

        return otherProducts
            .map((product) => {
                if (!product.firstRegistrationDate) return false;
                const maintenanceSubscriptionDeadline = formatCpDate(product.firstRegistrationDate)
                    .toMoment()
                    .add(...maintenanceSubscriptionDeadlineArguments);

                return currentDate.isBefore(maintenanceSubscriptionDeadline);
            })
            .some(Boolean);
    };

    const isMotorInsuranceAvailable = (): boolean => profileData?.identification?.customerType === 'Retail';

    useEffect(() => {
        if (isMaintenanceSubscriptionAvailable() || isMotorInsuranceAvailable()) {
            setShouldRender(true);
        }
    }, [contracts, productsWithMaintenance, otherProducts]);

    if (!content || (!productsWithMaintenance.length && !otherProducts.length)) {
        return null;
    }

    const getMotorInsuranceButtons = (): JSX.Element[] => {
        const brand = currentBrand.toLocaleLowerCase();
        if (brand !== 'vwfs')
            return [
                <MarketingCardButton
                    key={`motor-insurance-card-${brand}`}
                    brand={brand}
                    isMultipleMatch={false}
                    content={content}
                    cardType="MOTOR_INSURANCE"
                />,
            ];

        const productsBrands: string[] = uniq(
            productsWithMaintenance.map((product) => product.carCode).filter(Boolean) as string[],
        );
        return productsBrands.map((productBrand) => (
            <MarketingCardButton
                key={`motor-insurance-card-${productBrand}`}
                brand={productBrand}
                isMultipleMatch={productsBrands.length > 1}
                content={content}
                cardType="MOTOR_INSURANCE"
            />
        ));
    };

    const maintenanceSubscriptionCard = (
        <Card
            testId="maintenance-subscription-card"
            element="article"
            title={content?.maintenanceSubscription.title || ''}
            imageSrc={content?.maintenanceSubscription.imageUrl || ''}
            buttons={[
                <MarketingCardButton
                    key={`maintenance-subscription-card-button`}
                    onlineServicePlanUrl={onlineServicePlanUrl}
                    content={content}
                    cardType="MAINTENANCE_SUBSCRIPTION"
                />,
            ]}
            footer
        >
            {content?.maintenanceSubscription.text}
        </Card>
    );

    const motorInsuranceCard = (
        <Card
            testId="motor-insurance-card"
            element="article"
            title={content?.motorInsurance.title || ''}
            imageSrc={content?.motorInsurance.imageUrl || ''}
            buttons={getMotorInsuranceButtons()}
            footer
        >
            {content?.motorInsurance.text}
        </Card>
    );

    return (
        <>
            {isMaintenanceSubscriptionAvailable() && <div className="u-mb-small">{maintenanceSubscriptionCard}</div>}
            {isMotorInsuranceAvailable() && motorInsuranceCard}
        </>
    );
};
