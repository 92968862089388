import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-8/apis';
import { useAnalyticsActionTracker, ValidatedCheckbox, ValidatedInput } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../../../../../navigation/paths';
import { EditViewNames } from '../../enums';
import { InitialValues } from './InitialValues';
import { IbanStateHandler } from './types';
import { ValidatedIbanInput } from './validated-iban-input/ValidatedIbanInput';
import { ibanValidationFrontendSchema, IbanValidatorErrors } from '@cp-fr/common';
import { CpDataApi } from '../../../../../../../cp-xhr';
import { AxiosError } from 'axios';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { IbanValidationError } from './validated-iban-input/IbanValidationError';

type IbanViewProps = {
    setCurrentView: (newEditView: EditViewNames) => void;
    ibanViewValues: InitialValues;
    setIbanViewValues: (ibanValues: InitialValues) => void;
};

export const IbanView: React.FC<IbanViewProps> = ({ setCurrentView, ibanViewValues, setIbanViewValues }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const history = useHistory();
    const translationPrefix = 'iban-section.edit.validation';
    const ibanTranslationPrefix = `${translationPrefix}.iban`;
    const ibanHolderTranslationPrefix = `${translationPrefix}.iban-holder`;

    const { onAction: onEditCancel } = useAnalyticsActionTracker('financialDetailsEditIbanSectionCancel');

    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});
    const [isValidating, setIsValidating] = useState<boolean>(false);

    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };

    const validateApiFunc = async (iban: string) => {
        return await CpDataApi.post(getIbanValidationEndpoint(), { iban });
    };

    const getErrorCode = (error: AxiosError) =>
        parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(error).code;

    const errorMessages: IbanValidatorErrors = {
        iban: {
            required: t(`${ibanTranslationPrefix}.required`),
            validCountryCode: t(`${ibanTranslationPrefix}.invalid-iban`),
            sameIban: t(`${ibanTranslationPrefix}.same-iban`),
            ibanLength: t(`${ibanTranslationPrefix}.invalid-iban`),
            invalidIban: t(`${ibanTranslationPrefix}.invalid-iban`),
            ibanValidatorUnavailable: t(`${ibanTranslationPrefix}.iban-validator-unavailable`),
        },
        ibanHolder: {
            required: t(`${ibanHolderTranslationPrefix}.required`),
            maxLength: t(`${ibanHolderTranslationPrefix}.too-long`),
        },
    };

    const validationSchema = ibanValidationFrontendSchema(
        setIsValidating,
        ibanStateHandler,
        ibanViewValues.currentIban,
        validateApiFunc,
        getErrorCode,
        errorMessages,
    );

    const handleStepChange = (values: InitialValues): void => {
        setIbanViewValues(values);
        setCurrentView(EditViewNames.FILE_UPLOAD_VIEW);
    };

    return (
        <>
            <Formik
                initialValues={ibanViewValues}
                validationSchema={validationSchema}
                onSubmit={handleStepChange}
                validateOnBlur={true}
            >
                {({ handleSubmit }): JSX.Element => (
                    <Form onSubmit={(e): void => e.preventDefault()} data-testid="iban-form">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ValidatedIbanInput
                                            isValidating={isValidating}
                                            ibanStateHandler={ibanStateHandler}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedInput
                                            label={t('iban-section.edit.iban-holder')}
                                            name={'ibanHolder'}
                                            inputMode={'text'}
                                            testId={'iban-holder'}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedCheckbox
                                            testId={'all-contracts'}
                                            name={'allContracts'}
                                            label={t('iban-section.edit.all-contracts')}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer className={'u-mt'} center>
                                    <Button
                                        secondary
                                        onClick={(): void => {
                                            onEditCancel();
                                            history.push(dashboardPagePath());
                                        }}
                                        testId="dashboard"
                                    >
                                        {t('translation:editable-section-nav.back')}
                                    </Button>

                                    <Button onClick={handleSubmit} testId="submit-iban" type="submit">
                                        {t('translation:editable-section-nav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </>
    );
};
