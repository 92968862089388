import * as Yup from 'yup';
import * as formValidators from './requestValidators';
import { RequestFormValidatorErrors } from '../types';
import { RequestFormMessages } from '../messages';
import { ContractSelection } from '../../apis/services/types/request';

export type RequestFormType = {
    contractsSelection: ContractSelection[];
    categorySelection: string;
    authorizationDebit: formValidators.RequestAuthorizationDebitFormType | {};
    authorizationLeaveCountry: formValidators.RequestAuthorizationLeaveCountryFormType | {};
    deadline: formValidators.RequestDeadlineFormType | {};
    vehicleClaim: formValidators.RequestVehicleClaimFormType | {};
    death: formValidators.RequestFormDeathFormType | {};
    loss: formValidators.RequestLossFormType | {};
    requestContractTransfer: formValidators.RequestContractTransferFormType | {};
    documents: formValidators.RequestDocumentsFormType | {};
    registrationCertificateDuplicate: formValidators.RequestRegistrationCertificateDuplicateFormType | {};
    tenantChangeRequest: formValidators.RequestTenantChangeFormType | {};
    other: formValidators.RequestOtherFormType | {};
};

export const requestFormValidationSchema = (
    errors: RequestFormValidatorErrors = RequestFormMessages,
): Yup.SchemaOf<RequestFormType> => {
    const categorySelection = 'categorySelection';
    return Yup.object().shape({
        contractsSelection: Yup.array(),
        categorySelection: Yup.string().required(errors.categorySelection.required),
        authorizationDebit: formValidators.requestAuthorizationDebitValidationSchema(
            categorySelection,
            errors.requestAuthorizationDebitMessages,
        ),
        authorizationLeaveCountry: formValidators.requestAuthorizationLeaveCountryValidationSchema(
            categorySelection,
            errors.requestAuthorizationLeaveCountryMessages,
        ),
        deadline: formValidators.requestDeadlineValidationSchema(
            categorySelection,
            errors.requestDeadlineErrorMessages,
        ),
        vehicleClaim: formValidators.requestVehicleClaimValidationSchema(
            ['categorySelection', 'contractsSelection'],
            errors.requestVehicleClaimErrorMessages,
        ),
        death: formValidators.requestFormDeathValidationSchema(
            ['categorySelection', 'contractsSelection'],
            errors.requestFormDeathErrorMessages,
        ),
        loss: formValidators.requestLossValidationSchema(categorySelection, errors.requestLossErrorMessages),
        requestContractTransfer: formValidators.requestContractTransferValidationSchema(
            categorySelection,
            errors.requestContractTransferMessages,
        ),
        documents: formValidators.requestDocumentsValidationSchema(
            categorySelection,
            errors.requestDocumentsErrorMessages,
        ),
        registrationCertificateDuplicate: formValidators.requestRegistrationCertificateDuplicateValidationSchema(
            categorySelection,
            errors.requestRegistrationCertificateDuplicateErrorMessages,
        ),
        tenantChangeRequest: formValidators.requestTenantChangeValidationSchema(
            categorySelection,
            errors.requestTenantChangeRequestErrorMessages,
        ),
        changeDurationMileage: formValidators.requestChangeDurationMileageValidationSchema(
            ['categorySelection', 'contractsSelection'],
            errors.requestChangeDurationMileageErrorMessages,
        ),
        other: formValidators.requestOtherValidationSchema(categorySelection, errors.requestOtherErrorMessages),
    });
};
