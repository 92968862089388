import { CPDate } from '@cp-shared-8/common-utilities';

export type PartialEarlySettlement = {
    requestDate: CPDate;
    amount: number;
    goal: string;
    reason: string;
    contractNumber: string;
};

export enum PostEarlySettlementError {
    CHANGE_TOO_LATE = 'CHANGE_TOO_LATE',
}

export type EarlySettlementRequestDate =
    | 'JANUARY'
    | 'FEBRUARY'
    | 'MARCH'
    | 'APRIL'
    | 'MAY'
    | 'JUNE'
    | 'JULY'
    | 'AUGUST'
    | 'SEPTEMBER'
    | 'OCTOBER'
    | 'NOVEMBER'
    | 'DECEMBER';

export type TotalEarlySettlement = {
    requestDate: EarlySettlementRequestDate;
    reason: string;
    contractNumber: string;
};

export type EarlySettlementError = 'CHANGE_TOO_LATE';
